import { IconProp } from "@fortawesome/fontawesome-svg-core";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

type BadgeArgs = {
  tooltipTitle: string;
  title: string;
  className?: string;
  icon?: IconProp;
  style?: { [key: string]: string };
};

export default function DirectoryRowBadge({
  tooltipTitle,
  title,
  icon,
  className,
  style,
}: BadgeArgs) {
  return (
    <span
      className={classNames("badge self-center", className)}
      data-bs-toggle="tooltip"
      title={tooltipTitle}
      style={style}
    >
      {icon && <FontAwesomeIcon className="mr-1" icon={icon} />}
      {title}
    </span>
  );
}
