import { isEmpty, pick, some, truncate, values } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/pro-regular-svg-icons";
import React from "react";
import { Location } from "../../../@types/appointments";

export default function AppointmentLocation(location: Location) {
  const locationIsExtended = some(
    ["address1", "address2", "zip", "city", "link", "country"],
    (key) => !isEmpty(location[key]),
  );

  function getLocationLinkText() {
    switch (location.meeting_link_type) {
      case "ms_teams":
        return "Microsoft Teams Meeting";
      case "google_meet":
        return "Google Meet";
      case "zoom":
        return "Zoom Meeting";
      default:
        return location.link && location.link?.length > 65
          ? truncate(location.link, { length: 65 })
          : location.link;
    }
  }

  console.log("LINK", getLocationLinkText());

  const addressForUri = encodeURIComponent(
    values(
      pick(location, ["address1", "address2", "zip", "city", "country"]),
    ).join(" "),
  );

  return (
    <>
      {(!isEmpty(location.name) || locationIsExtended) && (
        <div className="detail-location detail-block flex gap-2">
          <FontAwesomeIcon className="detail-icon" icon={faLocationDot} />
          <address>
            <>
              {location.name && (
                <div className="font-bold">{location.name}</div>
              )}
              {locationIsExtended && (
                <>
                  {location.address1 && <div>{location.address1}</div>}
                  {location.address2 && <div>{location.address2}</div>}
                  {(location.zip || location.city) && (
                    <div>{`${location.zip} ${location.city}`}</div>
                  )}
                  {location.country && <div>{location.country}</div>}
                  {location.link && (
                    <div>
                      <a
                        href={location.link}
                        target="_blank"
                        rel="nofollow noreferrer"
                      >
                        {getLocationLinkText()}
                      </a>
                    </div>
                  )}
                </>
              )}
            </>
          </address>
        </div>
      )}
      {location.maps_url && (
        <div className="detail-map">
          <a
            href={`https://www.google.de/maps?q=${addressForUri}`}
            target="_blank"
            rel="noreferrer"
          >
            <img src={location.maps_url} className="w-full" />
          </a>
        </div>
      )}
    </>
  );
}
