import React from "react";
import createUndoPlugin from "@draft-js-plugins/undo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRotateLeft,
  faArrowRotateRight,
} from "@fortawesome/pro-solid-svg-icons";

export default function customUndoPlugin() {
  return createUndoPlugin({
    undoContent: <FontAwesomeIcon icon={faArrowRotateLeft} />,
    redoContent: <FontAwesomeIcon icon={faArrowRotateRight} />,
    theme: {
      undo: "btn btn-sm btn-light",
      redo: "btn btn-sm btn-light",
    },
  });
}
