import { useInfiniteQuery, useMutation } from "react-query";

import fetchPaginatedData from "helpers/api/fetchPaginatedData";
import { fetchApi } from "helpers/reactQueryApi";

export function useInvites({ filter, status, enabled }) {
  return useInfiniteQuery(
    ["invites", filter, status],
    ({
      pageParam = `/api/v1/invites?filter=${filter}&status=${status}&limit=50`,
    }) => fetchPaginatedData({ pageParam }),
    {
      getNextPageParam: (lastPage) => lastPage.nextPage,
      enabled,
    },
  );
}

export function useAcceptInvite(options) {
  return useMutation(
    ({ inviteId }: { inviteId: string }) =>
      fetchApi(`/api/v1/invites/${inviteId}/accept`, {
        method: "POST",
      }),
    options,
  );
}

export function useDeclineInvite(options) {
  return useMutation(
    ({ inviteId }: { inviteId: string }) =>
      fetchApi(`/api/v1/invites/${inviteId}/reject`, {
        method: "POST",
      }),
    options,
  );
}
