import { FieldValues, useFormContext, UseFormRegister } from "react-hook-form";
import invariant from "invariant";

/**
 * Returns given register arg if present, fetches register from FormContext otherwise
 * @param register
 */
export default function useRegisterPropOrFromContext<T extends FieldValues>(
  register: UseFormRegister<T> | undefined,
): UseFormRegister<T> {
  const contextMethods = useFormContext<T>();
  if (!register && contextMethods) return contextMethods.register;
  invariant(
    register,
    "register must be provided directly or through FormContext",
  );

  return register;
}
