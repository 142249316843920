import React from "react";
import { SimpleMembership } from "../../@types";
import classNames from "classnames";

const fallbackImageUrl = "/assets/misc/profile_50x50.jpg";
const findImage = (membership: SimpleMembership, width, height): string => {
  const image = membership.images.find(
    (i) => i.width == width && i.height == height,
  );
  return image ? image.url : fallbackImageUrl;
};

export function ProfileImage({
  membership,
  className,
  size,
}: {
  membership: SimpleMembership;
  className?: string;
  size?: number;
}) {
  return (
    <img
      className={classNames("rounded-full", className || "w-5 h-5")}
      src={findImage(membership, size || 50, size || 50)}
      alt={""}
      aria-hidden
    />
  );
}

export default function SimpleMembershipShow({
  membership,
}: {
  membership: SimpleMembership;
}) {
  return (
    <div className={"inline-block"}>
      {membership.id ? (
        <a
          href={`/members/${membership.id}`}
          className={"flex flex-row items-center gap-2"}
        >
          <ProfileImage membership={membership} />
          <span>{membership.name}</span>
        </a>
      ) : (
        <span className={"text-muted"}>{membership.name}</span>
      )}
    </div>
  );
}
