import React from "react";
import { RouteObject } from "helpers/tixxt-router";
import BackboneFeature from "components/tixxt/BackboneFeature";
import DirectoryPage from "components/directory/DirectoryPage";

const backboneRoutes: RouteObject[] = [
  // {
  //   path: "/directory",
  //   element: (
  //     <BackboneFeature
  //       feature="Directory"
  //       action="showList"
  //       paramKeys={["group_id", "category_id"]}
  //     />
  //   ),
  // },
  {
    path: "/directory/:category_id",
    element: (
      <BackboneFeature
        feature="Directory"
        action="showList"
        paramKeys={["group_id", "category_id"]}
      />
    ),
  },
  // {
  //   path: "/members",
  //   element: (
  //     <BackboneFeature
  //       feature="Directory"
  //       action="showList"
  //       paramKeys={["group_id"]}
  //     />
  //   ),
  // },
  //{
  //  path: "/directory/channels",
  //  element: (
  //    <BackboneFeature feature="Directory" action="showList" paramKeys={[]} />
  //  ),
  //},
  {
    path: "/groups/:group_id/directory",
    element: (
      <BackboneFeature
        feature="Directory"
        action="showList"
        paramKeys={["group_id", "category_id"]}
      />
    ),
  },
  {
    path: "/groups/:group_id/directory/:category_id",
    element: (
      <BackboneFeature
        feature="Directory"
        action="showList"
        paramKeys={["group_id", "category_id"]}
      />
    ),
  },
  {
    path: "/groups/:group_id/members",
    element: (
      <BackboneFeature
        feature="Directory"
        action="showList"
        paramKeys={["group_id"]}
      />
    ),
  },
];

const reactRoutes: RouteObject[] = [
  { path: "/directory", element: <DirectoryPage preselectedTab="members" /> },
  { path: "/members", element: <DirectoryPage preselectedTab="members" /> },
  { path: "/channels", element: <DirectoryPage preselectedTab="channels" /> },
  {
    path: "/directory/channels",
    element: <DirectoryPage preselectedTab="channels" />,
  },
  {
    path: "/directory/:category_id",
    element: <DirectoryPage preselectedTab="groups" />,
  },
];

export default reactRoutes.concat(backboneRoutes);
