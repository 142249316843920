import React from "react";

import createInlineMentionPlugin from "components/activitystreams/composer/inlineMentionPlugin";
import createToolbarPlugin from "components/shared/markdownEditor/editorPlugins/toolbarPlugin";
import createMarkdownPlugin from "components/shared/markdownEditor/editorPlugins/markdownPlugin";
import createUndoPlugin from "components/shared/markdownEditor/editorPlugins/undoPlugin";
import createShortcutsPlugin from "components/shared/markdownEditor/editorPlugins/shortcutsPlugin";
import createLinkifyPlugin from "@draft-js-plugins/linkify";
import createBlockBreakoutPlugin from "draft-js-block-breakout-plugin";
import createEmojiPlugin, { defaultTheme } from "@draft-js-plugins/emoji";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFaceSmile } from "@fortawesome/pro-regular-svg-icons";

interface HOCTypes {
  enableTixxtMentions?: boolean;
}

const withBasicMarkdownPlugins = (BaseComponent) => {
  class HOC extends React.Component<HOCTypes> {
    plugins: Array<unknown>;
    pluginComponents: Record<PropertyKey, unknown>;

    constructor(props: HOCTypes) {
      super(props);

      const [toolbarPlugin, Toolbar] = createToolbarPlugin();
      const markdownPlugin = createMarkdownPlugin();
      const undoPlugin = createUndoPlugin();
      const linkifyPlugin = createLinkifyPlugin();
      const blockBreakoutPlugin = createBlockBreakoutPlugin();
      const shortcutsPlugin = createShortcutsPlugin();
      const emojiPlugin = createEmojiPlugin({
        useNativeArt: true,
        selectButtonContent: <FontAwesomeIcon icon={faFaceSmile} />,
        theme: {
          ...defaultTheme,
          emojiSelectButton: "btn btn-sm btn-light",
          emojiSelectButtonPressed: "btn btn-sm btn-light active",
        },
      });

      this.plugins = [
        toolbarPlugin,
        markdownPlugin,
        undoPlugin,
        linkifyPlugin,
        blockBreakoutPlugin,
        shortcutsPlugin,
        emojiPlugin,
      ];

      this.pluginComponents = {
        Toolbar,
        UndoButton: undoPlugin.UndoButton,
        RedoButton: undoPlugin.RedoButton,
        EmojiSuggestions: emojiPlugin.EmojiSuggestions,
        EmojiSelect: emojiPlugin.EmojiSelect,
      };

      if (props.enableTixxtMentions) {
        const mentionPlugin = createInlineMentionPlugin();

        this.plugins = [mentionPlugin.inlineMentionPlugin, ...this.plugins];
        this.pluginComponents = {
          ...this.pluginComponents,
          InlineMentionSuggestions: mentionPlugin.InlineMentionSuggestions,
        };
      }
    }

    render() {
      return (
        <BaseComponent
          plugins={this.plugins}
          pluginComponents={this.pluginComponents}
          {...this.props}
        />
      );
    }
  }

  return HOC;
};

export default withBasicMarkdownPlugins;
