import { enGB, de, es, fr } from "date-fns/locale";
import { format, formatDistanceToNow, setDefaultOptions } from "date-fns";
import documentReady from "helpers/documentReady";

const LOCALE_LOOKUP = {
  de_DU: de,
  de: de,
  en: enGB,
  es: es,
  fr: fr,
};

documentReady(() => {
  setDefaultOptions({ locale: LOCALE_LOOKUP[I18n.currentLocale()] });
});

export default function formatDate(
  date: Date | number,
  dateFormat: string,
  options = {},
) {
  return format(date, dateFormat, {
    ...options,
  });
}

export function formatDateFromNow(date: Date | number, options = {}) {
  return formatDistanceToNow(date, {
    ...options,
  });
}
