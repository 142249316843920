import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { MatchFunction, MatchResult } from "path-to-regexp";
import { isEqual, isNumber, isString, noop } from "lodash";
import {
  compileRouteMemoized,
  compileRoutesMemoized,
  Params,
} from "helpers/tixxt-router/compilation";
import "helpers/tixxt-router/history-events";
import invariant from "invariant";
import { Group } from "../@types";
import { frontendByLocation } from "components/layout/TixxtContent";

export type NavigateFunction = (
  to: URL | string | number,
  options?: { replace?: boolean; state?: object },
) => void;

export type RouteObject = {
  path: string;
  element: JSX.Element | null;
  // Force navigation with turbo when this route is matched, useful if backend runs code on access
  forceTurbo?: boolean;
};
export type CompiledRoute = {
  forceTurbo?: boolean;
  path: string;
  match: MatchFunction<Params>;
  element: JSX.Element | null;
};

export type MatchedRoute = MatchResult<Params> & {
  element: JSX.Element | null;
  forceTurbo?: boolean;
};

// Helper to build locationState from window.location
function getLocationState() {
  return {
    pathname: location.pathname,
    search: location.search,
    hash: location.hash,
    state: history.state,
  };
}

// Returns the params in a (sub-)component that was determined with useRoutes
export function useParams(): Params {
  return useContext(ParamsContext);
}

// Returns a tuple of URLSearchParams and a noop setSearchParams function, which is only returned for legacy reasons
// Please use navigate to change the searchParams explicitly
export function useSearchParams(): [
  URLSearchParams,
  (URLSearchParams) => void,
] {
  const location = useLocation();

  const result = useMemo<[URLSearchParams, (URLSearchParams) => void]>(
    () => [new URLSearchParams(location.search), noop],
    [location.search],
  );

  return result;
}

// set search param für current url
export function setSearchParam(key: string, value: string) {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);

  if (value) {
    params.set(key, value);
  } else {
    params.delete(key);
  }

  const newUrlString =
    params.size > 0 ? `${url.pathname}?${params}` : url.pathname;

  window.history.replaceState({}, "", newUrlString);
}

// removes all search params from current url
export function removeSearchParams() {
  const url = new URL(window.location.href);
  url.search = "";

  window.history.replaceState({}, document.title, url.toString());
}

// The function to trigger a SPA navigation
// Should only receive routes that are handled by TixxtContent
// This should only be used for redirects, for user navigation use regular links (or GET-forms)
export const navigate: NavigateFunction = (to, options) => {
  if (!window.Turbo?.session.drive) {
    debug("⚛️ navigate LEGACY", to.toString(), options);
    invariant(
      isString(to),
      "`to` param must be String when using navigate outside of SPA frontend",
    );
    window.location.assign(to);
    return;
  }

  if (navigateToUnjoinedGroup(to.toString())) {
    window.location.assign(to.toString());
    return;
  }

  // Turbo.visit if navigating from spa to turbo
  const toPathname = to instanceof URL ? to.pathname : to.toString();
  if (
    frontendByLocation(location.pathname) === "spa" &&
    frontendByLocation(toPathname) === "turbo"
  ) {
    window.Turbo?.visit(toPathname, {});
    return;
  }

  debug("⚛️ navigate", to.toString(), options);
  if (isNumber(to)) {
    history.go(to);
  } else {
    const fn = options?.replace ? history.replaceState : history.pushState;
    fn(options?.state, "", to);
  }
};

function navigateToUnjoinedGroup(to: string) {
  let url;
  try {
    url = new URL(to.toString());
  } catch (_) {
    return false;
  }

  const pathnameParts = url.pathname.split("/");
  const groupSlugInPath =
    pathnameParts[1] === "groups" ? pathnameParts[2] : null;

  if (!groupSlugInPath) return false;

  return !(
    Preload.my_groups.find((group: Group) => group.slug === groupSlugInPath) !=
    null
  );
}

// Reimplementation of react-router useNavigate
// You can import and use navigate directly if you fancy
export function useNavigate() {
  return navigate;
}

// Can be used to find a matching route for a given path
// Is used to determine if a path can be handled by TixxtContent or should go through turbo
export function matchRoutes(
  routes: RouteObject[],
  path: string,
): MatchedRoute | null {
  const compiledRoutes = compileRoutesMemoized(routes);

  let matchResult: MatchedRoute | null = null;
  for (const route of compiledRoutes) {
    const result = route.match(path);
    if (result) {
      matchResult = {
        ...result,
        element: route.element,
        forceTurbo: route.forceTurbo,
      };
      break;
    }
  }

  return matchResult;
}

const defaultParams = {};
const ParamsContext = React.createContext<Params>(defaultParams);
const LocationContext = React.createContext(getLocationState());

// Main hook to use routes in components
// Returns the element of the first matching route wrapped in params provider
export function useRoutes(routes: RouteObject[]): JSX.Element | null {
  const { pathname } = useLocation();
  const matchedRoute = matchRoutes(routes, pathname);

  const lastParams = useRef<Params>(defaultParams);
  if (!isEqual(lastParams, matchedRoute?.params)) {
    lastParams.current = matchedRoute?.params || defaultParams;
  }

  if (!matchedRoute?.element) return null;

  return (
    <ParamsContext.Provider value={lastParams.current}>
      {matchedRoute.element}
    </ParamsContext.Provider>
  );
}

// Returns true if the given path matches the given pattern, false otherwise
// Good if you want to mark an element active depending on the current path
export function matchPath(pattern: string, path: string): boolean {
  return !!compileRouteMemoized(pattern).match(path);
}

type BrowserRouterProps = {
  children?: React.ReactNode;
};
// Single source of location state
export const BrowserRouter: React.FC<BrowserRouterProps> = ({ children }) => {
  const [locationState, setLocationState] = useState(getLocationState);

  useEffect(() => {
    function updateLocationState() {
      const maybeNewLocationState = getLocationState();
      if (!isEqual(maybeNewLocationState, locationState)) {
        setLocationState(maybeNewLocationState);
      }
    }

    const events = [
      "popstate",
      "hashchange",
      "tixxt:pushstate",
      "tixxt:replacestate",
    ];
    for (const event of events)
      window.addEventListener(event, updateLocationState);

    // RedirectToStartpage navigates before eventListener is bound but after default state is set
    updateLocationState();

    return () => {
      for (const event of events)
        window.removeEventListener(event, updateLocationState);
    };
  }, [locationState]);

  return (
    <LocationContext.Provider value={locationState}>
      {children}
    </LocationContext.Provider>
  );
};

// Hook to get current location state and trigger rerender when it changes
export function useLocation() {
  return useContext(LocationContext);
}
