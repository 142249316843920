import React, { Fragment } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import { get, map } from "lodash";

import humanizeFileSize from "../../../helpers/files/humanizeFileSize";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faCloudDownload } from "@fortawesome/pro-regular-svg-icons";

const isImage = (contentType) =>
  contentType && contentType.indexOf("image/") === 0;
const isPDF = (contentType) => contentType === "application/pdf";
const isOpenable = (contentType) => isImage(contentType) || isPDF(contentType);

export function FileName({
  id,
  handleNavigate,
  name,
  version,
  navigateDisabled,
}) {
  let displayName = name;
  if (name.length > 71) {
    const firstHalf = name.slice(0, 35);
    const secondHalf = name.slice(-35, name.length);
    displayName = (
      <>
        {firstHalf}
        <span className={"text-muted"}>…</span>
        {secondHalf}
      </>
    );
  }
  return (
    <td className="col-name whitespace-nowrap">
      {navigateDisabled ? (
        <span className="disabled" title={name}>
          {displayName}
        </span>
      ) : (
        <a
          href={`/files/${id}`}
          onClick={handleNavigate}
          data-id={id}
          data-type="file"
          title={name}
        >
          {displayName}
        </a>
      )}
      {version && version.major ? (
        <span className="text-muted"> v{version.major}</span>
      ) : null}
    </td>
  );
}
FileName.propTypes = {
  id: PropTypes.string,
  handleNavigate: PropTypes.func,
  name: PropTypes.string,
  version: PropTypes.shape({ major: PropTypes.number }),
  navigateDisabled: PropTypes.bool,
};

function FileInfo({
  id,
  name,
  handleNavigate,
  version,
  size,
  author,
  updatedAt,
  extraColumns,
  profile,
  contentType,
  navigateDisabled,
}) {
  const openUrl = `/api/storage/files/${id}/get/${name}?_=v${get(
    version,
    "major",
    "0",
  )}`;
  const downloadUrl = `${openUrl}&download`;

  return (
    <Fragment>
      <FileName
        id={id}
        handleNavigate={handleNavigate}
        name={name}
        version={version}
        navigateDisabled={navigateDisabled}
      />
      {map(extraColumns, ({ Data }, i) => (
        <Data key={i} profile={profile} />
      ))}
      <td className="col-size whitespace-nowrap">{humanizeFileSize(size)}</td>
      <td className="col-author max-w-[250px] truncate">
        {get(author, "deleted")
          ? get(author, "displayName") ||
            window.Preload.current_network.config.anonymize_membership_name
          : get(author, "displayName") || get(author, "name") || null}
      </td>
      <td className="col-updated_at whitespace-nowrap">
        {moment(updatedAt).calendar()}
      </td>
      <td className="col-actions text-right whitespace-nowrap">
        {isOpenable(contentType) ? (
          <a
            href={openUrl}
            target="_blank"
            rel="noopener noreferrer"
            title={I18n.t("js.files.show.open_file")}
            className="mr-2"
          >
            <FontAwesomeIcon icon={faEye} />
          </a>
        ) : null}
        <a
          href={downloadUrl}
          target="_blank"
          rel="noopener noreferrer"
          title={I18n.t("js.files.show.download_file")}
          aria-label={I18n.t("js.files.show.download_file")}
        >
          <FontAwesomeIcon icon={faCloudDownload} />
        </a>
      </td>
    </Fragment>
  );
}
FileInfo.propTypes = {
  ...FileName.propTypes,
  size: PropTypes.number,
  author: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    displayName: PropTypes.string,
  }),
  updatedAt: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  extraColumns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.func.isRequired,
      Data: PropTypes.func.isRequired,
    }),
  ),
  profile: PropTypes.object,
  contentType: PropTypes.string,
};

export default FileInfo;
