import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { map } from "lodash";
import classNames from "classnames";

type DirectoryRowArgs = {
  title: string;
  url: string;
  imageUrl?: string;
  fallbackIcon?: IconProp;
  actions?: React.ReactNode;
  badges?: React.ReactNode;
  children?: React.ReactNode;
  titleIcons?: { icon: IconProp; title: string }[];
};

export default function DirectoryRow({
  title,
  url,
  imageUrl,
  fallbackIcon,
  children,
  actions,
  badges,
  titleIcons,
}: DirectoryRowArgs) {
  return (
    <div className="flex flex-col sm:flex-row flex-wrap py-3 gap-3">
      <div className="flex gap-3 items-start">
        <div className="w-16 h-16 flex-shrink-0 items-center justify-center">
          {imageUrl ? (
            <img
              className="h-16 w-16 rounded-full object-cover"
              src={imageUrl}
              alt={title}
            />
          ) : (
            fallbackIcon && (
              <div className="flex h-16 w-16 items-center align-middle justify-center border border-neutral border-solid border-1 rounded-full bg-neutral">
                <FontAwesomeIcon
                  className="max-h-16 max-w-16 text-white"
                  icon={fallbackIcon}
                  size="2xl"
                />
              </div>
            )
          )}
        </div>
        <div className="media-body grow">
          <div className="flex flex-1 gap-2">
            <div className="flex gap-1 items-center">
              {titleIcons &&
                map(titleIcons, (icon, index) => (
                  <FontAwesomeIcon
                    key={index}
                    icon={icon.icon}
                    title={icon.title}
                  />
                ))}
              <a className="media-heading font-medium" href={url}>
                {title}
              </a>
            </div>
          </div>
          {children}
        </div>
        <div className="flex flex-wrap gap-2">{badges && badges}</div>
      </div>
      {actions && (
        <div className="flex sm:justify-end items-center flex-grow">
          <div
            className={classNames(
              "max-sm:flex max-sm:flex-wrap max-sm:gap-2 max-sm:[&>*]:w-full max-sm:w-full",
              { "btn-group": window.innerWidth > 640 },
            )}
          >
            {actions}
          </div>
        </div>
      )}
    </div>
  );
}
