import React, { useContext } from "react";
import { WidgetContext } from "components/channels/widgets/WidgetContainer";
import { times } from "lodash";
import classNames from "classnames";
import { smartChunk } from "components/channels/widgets/smartChunk";
import Loading from "components/shared/Loading";
import { getWidgetContentColumns } from "components/channels/helpers";

const N_PER_COL = 4;

export default function ImagesWidget({ images }: { images?: any[] }) {
  const { w } = useContext(WidgetContext);

  const byColumn = images ? smartChunk(images, N_PER_COL, w) : [];

  return (
    <div
      className={classNames(
        "grid gap-1 overflow-hidden grid-cols-1 rounded-bl rounded-br",
        getWidgetContentColumns(w),
      )}
    >
      {times(w, (n) => {
        const layout =
          byColumn[n]?.length > 2 ? 4 : byColumn[n]?.length == 2 ? 2 : 1;

        return (
          <div
            key={n}
            className={`grid gap-1 overflow-hidden images-widget--${layout}`}
          >
            {byColumn[n]?.map((image, m) => (
              <div
                key={m}
                className={"flex overflow-hidden items-center justify-center"}
              >
                {image.id ? (
                  <img
                    alt=""
                    aria-hidden
                    src={`/api/storage/images/${image.id}/get/435x435`}
                    className={"w-full h-full object-cover object-center"}
                  />
                ) : (
                  <Loading />
                )}
              </div>
            ))}
          </div>
        );
      })}
    </div>
  );
}
