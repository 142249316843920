import React, { useState } from "react";
import { useAppointment } from "components/appointments/api";
import { useParams } from "helpers/tixxt-router";
import PageTitle, { Subtitle } from "components/layout/PageTitle";
import { isEmpty, startsWith } from "lodash";
import Toolbar from "components/appointments/details/Toolbar";
import AppointmentDetails from "components/appointments/details/AppointmentDetails";
import TranslationDropDown, {
  ORIGINAL_LANGUAGE_SIGNIFIER,
} from "components/shared/TranslationDropDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRepeat } from "@fortawesome/pro-regular-svg-icons";
import PageLoading from "components/shared/PageLoading";

export default function AppointmentPage() {
  const { id } = useParams();
  const [appointmentLang, setAppointmentLang] = useState<string | undefined>();
  const {
    data: appointment,
    isLoading,
    refetch,
  } = useAppointment(id, appointmentLang);

  const isRecurring =
    appointment?.type === "recurring_appointment" &&
    !startsWith(appointment?.id, "r-");

  const isModifiedRecurring =
    appointment?.type === "virtual_appointment" &&
    startsWith(appointment?.id, "r-");

  const handleLanguageSelect = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    language: string,
  ) => {
    if (language === ORIGINAL_LANGUAGE_SIGNIFIER) {
      setAppointmentLang(undefined);
    } else {
      setAppointmentLang(language);
    }
  };

  return isLoading ? (
    <PageLoading />
  ) : (
    appointment && (
      <>
        <PageTitle title={appointment?.name}>
          {(isRecurring || isModifiedRecurring) && (
            <FontAwesomeIcon
              icon={faArrowsRepeat}
              title={I18n.t("js.recurring_appointment.icon.title")}
            />
          )}
          <TranslationDropDown
            handleLanguageSelect={handleLanguageSelect}
            object_language={Preload.current_network.language}
          />
          {appointmentLang && (
            <span
              style={{
                color: "grey",
                fontSize: "x-small",
              }}
            >
              (translated)
            </span>
          )}
        </PageTitle>
        <Subtitle className="mb-3">
          {isRecurring
            ? I18n.t("js.calendars.recurring_appointment.subtitle")
            : I18n.t("js.calendars.appointment.subtitle")}
        </Subtitle>
        <Toolbar
          id={appointment.id}
          type={appointment.type}
          can={appointment.can}
          participationLocked={
            appointment.attendee_configuration.participation_locked
          }
          participantsEmpty={isEmpty(appointment.participants)}
          isRecurring={isRecurring}
          name={appointment.name}
          isModifiedRecurring={isModifiedRecurring}
          previousOccurrenceUrl={appointment.previous_occurrence_url}
          recurringAppointmentUrl={appointment.recurring_appointment_url}
          nextOccurrenceUrl={appointment.next_occurrence_url}
          refetch={refetch}
          canceled={appointment.canceled}
          cancelProhibitedReason={appointment.cancel_prohibited_reason}
        />
        <AppointmentDetails
          {...appointment}
          isRecurring={isRecurring}
          refetch={refetch}
          translated={!!appointmentLang}
        />
      </>
    )
  );
}
