import React from "react";
import PropTypes from "prop-types";
import { get, map, isNil } from "lodash";
import moment from "moment";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments } from "@fortawesome/pro-regular-svg-icons";

// Helper to format a given momentDate with the localised time format
const formatTime = (momentDate) =>
  momentDate.format(I18n.t("js.time.formats.short_time"));

function FormattedTime({ start, end, allDay, day }) {
  if (allDay || day.isBetween(start, end, "day", "()")) {
    // allDay appointment or day is between start/end, check by day and exclude start/end days
    return null;
  }

  if (start.isSame(day, "day") && end.isSame(day, "day")) {
    // start and end on this day
    return (
      <span className="time">{`${formatTime(start)}-${formatTime(end)}`}</span>
    );
  } else if (start.isSame(day, "day")) {
    // start on this day
    return <span className="time">{formatTime(start)}</span>;
  } else if (end.isSame(day, "day")) {
    // end on this day
    return <span className="time">{`>${formatTime(end)}`}</span>;
  }

  // default return, when the appointment doesn't match a criteria above
  return null;
}

function AppointmentLine({
  name,
  date,
  html_url,
  day,
  markers,
  canceled,
  has_meeting,
  className,
}) {
  const start = moment(get(date, "start"));
  const end = isNil(get(date, "end")) ? moment(null) : moment(get(date, "end"));
  const allDay = get(date, "all_day");

  return (
    <div
      className={classnames(
        "h-5 flex gap-1",
        { "bg-gray-100": allDay },
        className,
      )}
      title={name}
    >
      <div className={"markers flex"}>
        {map(markers, (markerColor, index) => (
          <span
            key={index}
            className="w-1"
            style={{ backgroundColor: `#${markerColor}` }}
          />
        ))}
      </div>
      <a href={html_url} className={"text-sm truncate grow min-w-0"}>
        <FormattedTime start={start} end={end} allDay={allDay} day={day} />{" "}
        <span
          className={classnames("truncate", {
            "line-through": canceled,
          })}
        >
          {name}
        </span>
      </a>
      {has_meeting ? (
        <FontAwesomeIcon icon={faComments} className={"fa-sm text-muted"} />
      ) : null}
    </div>
  );
}

AppointmentLine.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  canceled: PropTypes.bool,
  date: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
    all_day: PropTypes.bool,
    time_zone: PropTypes.string,
  }),
  markers: PropTypes.arrayOf(PropTypes.string),
  day: PropTypes.object,
  has_meeting: PropTypes.bool,
};

export default AppointmentLine;
