import ToggleFavoriteButton from "components/shared/favoriteButtons/ToggleFavoriteButton";
import React from "react";
import { useMutation } from "react-query";
import { fetchApi } from "helpers/reactQueryApi";
import { updateChannelFavorited } from "../../channels/api";

export default function ToggleChannelFavoriteButton({
  channelSlug,
  favorited,
}: {
  channelSlug: string;
  favorited: boolean;
}) {
  const { mutate, isLoading } = useMutation(
    ({ favorite }: { favorite: boolean }) => {
      return fetchApi(`/channels/${channelSlug}/favorite`, {
        method: favorite ? "POST" : "DELETE",
      });
    },
    {
      onSuccess: (data) => updateChannelFavorited(channelSlug, data.favorited),
    },
  );

  return (
    <ToggleFavoriteButton
      title={
        favorited
          ? I18n.t("js.page_title.remove_group_from_favorites")
          : I18n.t("js.page_title.add_group_to_favorites")
      }
      toggleFavorited={() => mutate({ favorite: !favorited })}
      isLoading={isLoading}
      favorited={favorited}
    />
  );
}
