import { chunk } from "lodash";

export function smartChunk<T>(
  whatever: T[],
  limitPerCol: number,
  numberCols: number,
): T[][] {
  const total = whatever.length;

  // Try all possible variants, depending on numberCols
  // i = number of columns to chunk
  for (let i = 0; i < numberCols; i++) {
    if (total <= numberCols - i + limitPerCol * i) {
      const out: T[][] = [];
      const standaloneCount = numberCols - i;
      // `Chunk ${total} into ${standaloneCount} standalone and ${i} chunked `,
      for (let j = 0; j < standaloneCount; j++) {
        // Standalone
        if (whatever[j]) out.push([whatever[j]]);
      }
      // Chunk remaining and concat
      return out.concat(
        chunk(whatever.slice(standaloneCount, total), limitPerCol),
      );
    }
  }

  return chunk(whatever, limitPerCol);
}
