import React, { useState, useEffect } from "react";
import { range, map, isString } from "lodash";
import classnames from "classnames";

const FILTER = map(range(65, 91), (charCode) => String.fromCharCode(charCode));

type GlossaryArgs = {
  onSelect: (letter: string) => void;
  selectedLetter?: string;
};

export default function Glossary({ onSelect, selectedLetter }: GlossaryArgs) {
  const [selected, setSelected] = useState(selectedLetter || "");

  function select(
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    filter: string,
  ) {
    e.preventDefault();
    setSelected(filter);
    onSelect(filter);
  }

  useEffect(() => {
    isString(selectedLetter) && setSelected(selectedLetter);
  }, [selectedLetter]);

  return (
    <div className="row glossaryWrapper">
      <div className="span12">
        <div className="search-form">
          <div className="a-z-filter mt-4 flex flex-wrap gap-1">
            <a
              href="#"
              className={classnames("letter", {
                "active underline": selected === "",
              })}
              onClick={(e) => select(e, "")}
            >
              {I18n.t("js.apps.app.glossary.all_link")}
            </a>
            {map(FILTER, (filter) => (
              <a
                href={`#${filter}`}
                key={String(filter)}
                className={classnames("letter", {
                  "active underline": selected === filter,
                })}
                onClick={(e) => select(e, filter)}
              >
                {filter}
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
