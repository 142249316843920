import React, { ReactElement, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { IconDefinition } from "@fortawesome/pro-regular-svg-icons";
import classNames from "classnames";
import { isString } from "lodash";

type PartialToggleArgs = {
  onToggle?: (showPartial: boolean) => void;
  label: string | ((showPartial: boolean) => string);
  showIcon?: IconDefinition;
  hideIcon?: IconDefinition;
  iconClassName?: string | ((showPartial: boolean) => string);
  shouldShowPartial?: boolean;
  children: ReactElement[] | ReactElement;
};

export default function PartialToggle({
  label,
  showIcon,
  hideIcon,
  iconClassName,
  shouldShowPartial = false,
  children,
  onToggle,
}: PartialToggleArgs) {
  const [showPartial, setShowPartial] = useState(shouldShowPartial);
  return (
    <>
      <button
        className={classNames(
          "flex justify-between align-middle p-3 -mx-3 border-t border-t-neutral items-center hover:bg-neutral/10",
          { "mb-1": showPartial, "-mb-3": !showPartial },
        )}
        onClick={(e) => {
          e.preventDefault();
          onToggle && onToggle(showPartial);
          setShowPartial(!showPartial);
        }}
      >
        {isString(label) ? label : label(showPartial)}
        <FontAwesomeIcon
          className={
            isString(iconClassName)
              ? iconClassName
              : iconClassName && iconClassName(showPartial)
          }
          icon={
            showPartial ? hideIcon || faChevronUp : showIcon || faChevronDown
          }
        />
      </button>
      {showPartial && children}
    </>
  );
}
