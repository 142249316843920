import React, { useContext } from "react";
import { SimpleMembership } from "../../../@types";
import { WidgetContext } from "components/channels/widgets/WidgetContainer";
import { times } from "lodash";
import classNames from "classnames";
import { ProfileImage } from "components/shared/SimpleMembershipShow";
import { smartChunk } from "components/channels/widgets/smartChunk";
import { getWidgetContentColumns } from "components/channels/helpers";

const N_PER_COL = 3;

export default function PeopleWidget({
  memberships,
  profile_fields,
}: {
  memberships?: (SimpleMembership & { profile: { [key: string]: string } })[];
  profile_fields?: string[];
}) {
  const { w } = useContext(WidgetContext);

  const byColumn = memberships ? smartChunk(memberships, N_PER_COL, w) : [];

  return (
    <div
      className={classNames(
        "grid gap-4 h-full grid-cols-1 divide-neutral max-sm:divide-y sm:divide-x",
        getWidgetContentColumns(w),
        {
          "grid-rows-3": w == 3,
          "grid-rows-2": w == 2,
          "grid-rows-1": w == 1,
        },
        "sm:grid-rows-1",
      )}
    >
      {times(w, (n) => (
        <div
          className={classNames("px-4 grid grid-rows-3 gap-4 h-[21rem]")}
          key={n}
        >
          {byColumn[n]?.map((membership) =>
            byColumn[n].length == 1 ? (
              <StandalonePerson
                key={membership.id}
                membership={membership}
                profile_fields={profile_fields}
              />
            ) : (
              <RowPerson
                key={membership.id}
                membership={membership}
                profile_fields={profile_fields}
              />
            ),
          )}
        </div>
      ))}
    </div>
  );
}

type PersonProps = {
  membership: SimpleMembership & { profile: { [key: string]: string } };
  profile_fields?: string[];
};

function StandalonePerson({ membership, profile_fields }: PersonProps) {
  return (
    <div
      className={"row-span-3 flex flex-col items-center justify-center"}
      key={membership.id}
    >
      <ProfileImage
        membership={membership}
        size={128}
        className={"w-24 h-24"}
      />
      <a
        href={membership.html_url || "#"}
        className={"font-semibold mt-6 leading-6"}
      >
        {membership.name}
      </a>
      {profile_fields?.map((f) => (
        <span key={f} className={"text-sm leading-6"}>
          {membership.profile[f]}
        </span>
      ))}
    </div>
  );
}

function RowPerson({ membership, profile_fields }: PersonProps) {
  return (
    <div className={"grow flex gap-2 items-center h-full"} key={membership.id}>
      <div>
        <ProfileImage
          membership={membership}
          size={80}
          className={"w-10 h-10"}
        />
      </div>
      <div className={"flex flex-col leading-tight"}>
        <a href={membership.html_url || "#"} className={"font-semibold"}>
          {membership.name}
        </a>
        {profile_fields?.map((f) => (
          <span key={f} className={"text-sm"}>
            {membership.profile[f]}
          </span>
        ))}
      </div>
    </div>
  );
}
