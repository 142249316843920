import React from "react";
import { useParams } from "helpers/tixxt-router";
import ToolBar from "components/directory/groups/ToolBar";
import { useQuery } from "react-query";
import { stringify } from "query-string";
import { find, isEmpty, map, toNumber } from "lodash";
import DirectoryPagination from "components/directory/DirectoryPagination";
import { DirectoryGroup, Pagination } from "components/directory/types";
import GroupRow from "components/directory/groups/GroupRow";
import useDirectoryFilter from "../hooks/useDirectoryFilter";

type GroupData = {
  entries: DirectoryGroup[];
  pagination: Pagination;
};

export default function GroupListView() {
  const { category_id } = useParams();
  const category = find(Preload.current_network.group_categories, {
    slug: category_id,
  });

  const { filter, currentPage } = useDirectoryFilter();

  const { data, isLoading } = useQuery<GroupData, Error>(
    `/directory/${category_id}.json?${stringify(filter)}`,
    { enabled: !!category_id },
  );

  return category ? (
    <div className="flex flex-col gap-y-4">
      <ToolBar
        isLoading={isLoading}
        filter={filter}
        categoryName={category.name}
        categorySlug={category_id || ""}
        categoryPluralName={category.plural_name}
      />
      <DirectoryPagination
        currentPage={toNumber(currentPage)}
        pagination={data && data.pagination}
        isLoading={isLoading}
      >
        {data && !isEmpty(data?.entries)
          ? map(data.entries, (group) => <GroupRow key={group.id} {...group} />)
          : I18n.t("js.directory.empty_collection")}
      </DirectoryPagination>
    </div>
  ) : (
    <div>Category not found</div>
  );
}
