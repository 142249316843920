import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { map } from "lodash";

import WidgetTypes from "components/administration/widgetbar/WidgetTypes";
import WidgetbarLayout from "components/administration/widgetbar/WidgetbarLayout";
import WidgetPropertiesLayout from "components/administration/widgetbar/WidgetPropertiesLayout";
import NotFound from "components/shared/errors/NotFound";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner as faRegularSpinner } from "@fortawesome/pro-regular-svg-icons";
import { faArrowLeft as faSolidArrowLeft } from "@fortawesome/pro-solid-svg-icons";
import { setGroupId } from "actions/administration/widgetbar";
import { getWidgets } from "helpers/selectors/widgetbarSelectors";
import classnames from "classnames";
import { useMutation } from "react-query";
import withDragDropContext from "../../shared/withDragDropContext";
import {
  FETCH_CALENDARS_ACTIONS,
  FETCH_WIDGETBAR_ACTIONS,
} from "../../../actions/administration/widgetbar";
import { fetchApi } from "../../../helpers/reactQueryApi";

function WidgetbarForm({
  groupId,
  identifier,
}: {
  groupId?: string;
  identifier: string;
}) {
  const dispatch = useDispatch();

  useEffect(() => {
    groupId && dispatch(setGroupId({ groupId }));
  }, []);

  const context = groupId ? `/groups/${groupId}` : "";

  const {
    mutate: loadWidgetbar,
    isLoading,
    isError,
  } = useMutation<{ widgetbar: { widgets: any[] } }, unknown>(
    () => fetchApi(`${context}/administration/widgetbars/${identifier}.json`),
    {
      onSuccess: (data) => {
        dispatch({
          type: FETCH_WIDGETBAR_ACTIONS.SUCCESS,
          payload: data,
        });
      },
      onError: () => {
        toastr.error(I18n.t("js.administration.widgetbars.load_error"));
      },
    },
  );

  const { mutate: loadCalendars } = useMutation<{ calendars: any[] }, unknown>(
    () => fetchApi(`${context}/administration/widgetbars/calendars.json`),
    {
      onSuccess: (data) => {
        dispatch({
          type: FETCH_CALENDARS_ACTIONS.SUCCESS,
          payload: data,
        });
      },
    },
  );

  const { mutate: updateWidgetbar } = useMutation(
    (body: { widgetbar: { widgets: any[] } }) =>
      fetchApi(`${context}/administration/widgetbars/${identifier}.json`, {
        method: "PUT",
        body,
      }),
    {
      onSuccess: () => {
        toastr.success(I18n.t("js.administration.widgetbars.save_success"));
      },
      onError: () => {
        toastr.error(I18n.t("js.administration.widgetbars.save_error"));
      },
    },
  );

  useEffect(() => {
    loadWidgetbar();
    loadCalendars();
  }, []);

  const widgets = useSelector((state) => getWidgets(state));

  function onSubmit(e) {
    e.preventDefault();

    const body = {
      widgetbar: {
        widgets: map(widgets, (w) => {
          // backend need the string as a boolean
          if (w.properties.show_age) {
            return {
              ...w,
              properties: {
                ...w.properties,
                show_age: w.properties.show_age === "true",
              },
            };
          } else if (w.properties.count) {
            return {
              ...w,
              properties: {
                ...w.properties,
                count: parseInt(w.properties.count, 10),
              },
            };
          }
          return w;
        }),
      },
    };

    updateWidgetbar(body);
  }

  if (isLoading) {
    return (
      <div>
        <FontAwesomeIcon icon={faRegularSpinner} spin />
        <i className="fa fa-spinner fa-spin" />
        {I18n.t("js.administration.widgetbars.loading")}
      </div>
    );
  } else if (isError) {
    return <NotFound />;
  } else {
    return (
      <form onSubmit={onSubmit} autoComplete="off">
        <div
          className={classnames("btn-toolbar", {
            "justify-end": groupId,
          })}
        >
          {groupId ? null : (
            <div className="btn-group">
              <a className="btn btn-light" href="/administration/widgetbars">
                <FontAwesomeIcon
                  className="text-primary"
                  icon={faSolidArrowLeft}
                />
              </a>
            </div>
          )}
          <button type="submit" className="btn btn-primary">
            {I18n.t("js.save")}
          </button>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-4">
          <WidgetTypes identifier={identifier} />
          <WidgetbarLayout />
          <WidgetPropertiesLayout />
        </div>
      </form>
    );
  }
}

export default withDragDropContext(WidgetbarForm);
