import React from "react";
import PageTitle from "components/layout/PageTitle";
import { useParams } from "helpers/tixxt-router";
import ToggleChannelFavoriteButton from "components/shared/favoriteButtons/ToggleChannelFavoriteButton";
import { useChannel } from "components/channels/api";
import { useChannelLayout } from "components/channels/layout/api";
import { faGear } from "@fortawesome/pro-thin-svg-icons";
import DropDown, { DropDownItem } from "components/shared/DropDown";
import { ChannelLayout } from "./ChannelLayout";

export default function ChannelPage() {
  const { slug } = useParams();
  const { data: channel } = useChannel(slug);
  const { data: layoutData, isLoading: layoutLoading } = useChannelLayout(slug);

  const dropdownItems: React.JSX.Element[] = [];
  if (channel?.i.can.update)
    dropdownItems.push(
      <DropDownItem
        key={"update"}
        text={I18n.t("js.channels.title.dropdown.edit_channel")}
        url={`/channels/${channel.id}/edit`}
      />,
    );

  if (channel?.i.can.update_blog)
    dropdownItems.push(
      <DropDownItem
        key={"update_blog"}
        text={I18n.t("js.channels.title.dropdown.edit_blog")}
        url={`/blogs/${channel.blog_id}/edit`}
      />,
    );

  return (
    <>
      {(channel?.status === "draft" || channel?.status === "archived") &&
      !channel?.i.can.update ? (
        <p>{I18n.t(`js.channels.${channel.status}.body`)}</p>
      ) : (
        <>
          <PageTitle title={channel?.title || ""}>
            {channel && (
              <div className={"flex place-items-center"}>
                <ToggleChannelFavoriteButton
                  channelSlug={channel.slug}
                  favorited={channel.i.favorited}
                />
                {dropdownItems.length > 0 && (
                  <>
                    <DropDown.Toggle icon={faGear} buttonType="normal" small />
                    <DropDown alignment="start">{dropdownItems}</DropDown>
                  </>
                )}
              </div>
            )}
          </PageTitle>
          <ChannelLayout
            layoutData={layoutData}
            channelId={channel?.id}
            isLoading={layoutLoading}
          />
        </>
      )}
    </>
  );
}
