import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import classNames from "classnames";

export default function Hint({
  text,
  usabilityHint = false,
}: {
  text: string;
  usabilityHint?: boolean;
}) {
  return (
    <div
      className={classNames("flex gap-4 align-baseline text-muted", {
        "mb-6": usabilityHint,
      })}
    >
      {usabilityHint && (
        <FontAwesomeIcon className="ml-4" icon={faInfoCircle} size="2xl" />
      )}
      <span className="text-muted text-sm markdown-content prose">{text}</span>
    </div>
  );
}
