import React from "react";
import { useQuery } from "react-query";
import ToolBar from "components/directory/channels/ToolBar";
import { isEmpty, map, toNumber } from "lodash";
import { stringify } from "query-string";
import ChannelRow, { Channel } from "components/directory/channels/ChannelRow";
import DirectoryPagination from "components/directory/DirectoryPagination";
import useDirectoryFilter from "../hooks/useDirectoryFilter";
import { Pagination } from "components/directory/types";

type ChannelData = {
  pagination: Pagination;
  entries: Channel[];
};

export default function ChannelListView() {
  const { filter, currentPage } = useDirectoryFilter();

  const { data, isLoading } = useQuery<ChannelData, Error>(
    `/directory/channels.json?${stringify(filter)}`,
  );

  return (
    <div className="flex flex-col gap-y-4">
      <ToolBar isLoading={isLoading} filter={filter} />
      <DirectoryPagination
        currentPage={toNumber(currentPage)}
        pagination={data && data.pagination}
        isLoading={isLoading}
      >
        {data && !isEmpty(data?.entries)
          ? map(data.entries, (channel) => (
              <ChannelRow key={channel.id} {...channel} />
            ))
          : I18n.t("js.directory.empty_collection")}
      </DirectoryPagination>
    </div>
  );
}
