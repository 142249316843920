import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import {
  faBullhorn,
  faBellSlash,
  faBell,
} from "@fortawesome/pro-regular-svg-icons";

import { fetchApi } from "helpers/reactQueryApi";
import DirectoryRow from "components/directory/DirectoryRow";
import DirectoryActionButton from "components/directory/DirectoryActionButton";
import DirectoryRowBadge from "components/directory/DirectoryRowBadge";

export type Channel = {
  id: string;
  slug: string;
  title: string;
  description: string;
  status: string;
  image: { _id: string };
  subscribers_count: number;
  is_subscribed: boolean;
};

export default function ChannelRow({
  id,
  slug,
  title,
  description,
  image,
  subscribers_count,
  is_subscribed,
  status,
}: Channel) {
  const [isSubscribed, setIsSubscribed] = useState(is_subscribed);
  const [subscribersCount, setSubscribersCount] = useState(subscribers_count);

  const { mutate: subscribeChannel } = useMutation(
    (channelId: string) =>
      fetchApi(`/channels/${channelId}/subscribe`, { method: "POST" }),

    {
      onSuccess: () => {
        setIsSubscribed(true);
        setSubscribersCount(subscribersCount + 1);
      },
      onError: (error: any) =>
        toastr.error(error.message || I18n.t("js.generic_error")),
    },
  );

  const { mutate: unsubscribeChannel } = useMutation(
    (channelId: string) =>
      fetchApi(`/channels/${channelId}/unsubscribe`, { method: "POST" }),

    {
      onSuccess: () => {
        setIsSubscribed(false);
        setSubscribersCount(subscribersCount - 1);
      },
      onError: (error: any) =>
        toastr.error(error.message || I18n.t("js.generic_error")),
    },
  );

  // to update row on page navigation
  useEffect(() => {
    setIsSubscribed(is_subscribed);
    setSubscribersCount(subscribers_count);
  }, [is_subscribed, subscribers_count]);

  return (
    <DirectoryRow
      title={title}
      url={`/channels/${slug}`}
      imageUrl={image._id && `/api/storage/images/${image._id}/get`}
      fallbackIcon={faBullhorn}
      badges={
        (status === "draft" || status === "archived") && (
          <DirectoryRowBadge
            tooltipTitle={I18n.t(`js.directory.channel_row.status.${status}`)}
            title={I18n.t(`js.directory.channel_row.status.${status}`)}
            className="text-muted bg-gray-100"
          />
        )
      }
      actions={
        <DirectoryActionButton
          icon={{
            icon: isSubscribed ? faBellSlash : faBell,
          }}
          text={
            isSubscribed
              ? I18n.t("js.directory.channel_row.unsubscribe.title")
              : I18n.t("js.directory.channel_row.subscribe.title")
          }
          onClick={() =>
            isSubscribed ? unsubscribeChannel(id) : subscribeChannel(id)
          }
        />
      }
    >
      <p className="text-muted text-sm">
        {I18n.t("js.directory.channel_row.subscriptions_count", {
          count: subscribersCount,
        })}
      </p>
      <p className="max-w-4xl mt-1 text-sm whitespace-pre-line">
        {description}
      </p>
    </DirectoryRow>
  );
}
