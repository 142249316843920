import React, { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/pro-regular-svg-icons";

import Label from "components/shared/form/Label";
import { UseFormRegister } from "react-hook-form";
import Checkbox from "components/shared/form/fields/Checkbox";

export default function AttendeeConfigurationField({
  register,
}: {
  register: UseFormRegister<any>;
}) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  return (
    <div className="control-group">
      <Label
        label={I18n.t("js.calendars.appointment.attendee_configuration.label")}
      />
      <div className="controls border-box flex flex-col gap-y-3 p-3">
        <div className="control-group items-center">
          <Label
            label={I18n.t("js.calendars.attendee_configuration.total.label")}
          />
          <div className="controls">
            <div className="input-group w-32">
              <input
                type="number"
                {...register("attendee_configuration.total")}
                ref={(e) => {
                  register("attendee_configuration.total").ref(e);
                  inputRef.current = e;
                }}
                min="0"
              />
              <button
                className="btn btn-light"
                onClick={(e) => {
                  e.preventDefault();
                  inputRef?.current?.focus();
                }}
              >
                <FontAwesomeIcon icon={faUsers} />
              </button>
            </div>
          </div>
        </div>
        <div className="inline-controls">
          <Checkbox
            name="attendee_configuration.total_attendees_enforced"
            register={register}
            label={I18n.t(
              "js.calendars.attendee_configuration.total_attendees_enforced.label",
            )}
          />
          <Checkbox
            name="attendee_configuration.user_can_opt"
            register={register}
            label={I18n.t(
              "js.calendars.attendee_configuration.user_can_opt.label",
            )}
          />
          <Checkbox
            name="attendee_configuration.show_participants_to_admins_only"
            register={register}
            label={I18n.t(
              "js.calendars.attendee_configuration.show_participants_to_admins_only.label",
            )}
          />
        </div>
      </div>
    </div>
  );
}
