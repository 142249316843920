import React, { useEffect } from "react";
import {
  matchRoutes,
  navigate,
  RouteObject,
  useRoutes,
} from "helpers/tixxt-router";
import appRoutes from "../../routes";
import BackboneHistoryShim from "components/tixxt/BackboneHistoryShim";
import invariant from "invariant";
import { memoize } from "lodash";
import { getConfirmationMessage } from "../../hooks/useInterceptorPrompt";

let spaRoot: HTMLElement | null;

const routes = Preload.current_member ? appRoutes : [];

export default function TixxtContent() {
  const element = useRoutes(routes);

  useEffect(() => {
    if (element) toggleFrontend("spa", { source: "routes" });
  }, [element]);

  return (
    <>
      {element}
      <div id={"backbone-root"} />
      <BackboneHistoryShim />
      <TurboCompat routes={routes} />
    </>
  );
}

function TurboCompat({ routes }: { routes: RouteObject[] }) {
  useEffect(() => {
    function spaNavigateInstead(event) {
      // Cancel visit if confirmation is required but not given
      const confirmationMessage = getConfirmationMessage();
      if (confirmationMessage && !confirm(confirmationMessage)) {
        event.preventDefault();
        return;
      }

      // Turn turbo:visit into spa navigate
      const url = new URL(event.detail.url);
      const match = matchRoutes(routes, url.pathname);
      if (match && !match.forceTurbo) {
        event.preventDefault();
        navigate(url);
      }
    }

    window.addEventListener("turbo:before-visit", spaNavigateInstead);
    return () => {
      window.removeEventListener("turbo:before-visit", spaNavigateInstead);
    };
  }, []);
  return null;
}

function toggleFrontend(type: "spa" | "turbo", meta?: { source?: string }) {
  if (!spaRoot) {
    spaRoot = document.getElementById("spa-root");
    invariant(spaRoot, "spa-root missing");
  }

  if (type == "spa") {
    if (spaRoot.classList.contains("hidden")) {
      debug(`⚛️ toggleFrontend spa (source=${meta?.source})`);
      spaRoot.classList.toggle("hidden", false);
    }
    document.getElementById("turbo-root")?.classList.toggle("hidden", true);
  } else if (type == "turbo") {
    if (!spaRoot.classList.contains("hidden")) {
      debug(`⚡️ toggleFrontend turbo (source=${meta?.source})`);
      spaRoot.classList.toggle("hidden", true);
    }
    document.getElementById("turbo-root")?.classList.toggle("hidden", false);
  }
}

export const frontendByLocation = memoize(function (path: string) {
  if (matchRoutes(routes, path)) {
    return "spa";
  } else {
    return "turbo";
  }
});

window.addEventListener("popstate", () => {
  toggleFrontend(frontendByLocation(location.pathname), { source: "popstate" });
});
window.addEventListener("turbo:render", () => {
  toggleFrontend(frontendByLocation(location.pathname), {
    source: "turbo:render",
  });
});

function nativeLocaleLinks(event) {
  // prevent turbo from handling this link click
  const url = new URL(event["detail"].url);
  if (url.searchParams.get("locale")) event.preventDefault();
}

window.addEventListener("turbo:click", nativeLocaleLinks);
