import { fetchApi } from "helpers/reactQueryApi";
import { useMutation, UseMutationOptions } from "react-query";

export const fetchGroup = async (groupSlug) => {
  return await fetchApi(`/api/v1/groups/${groupSlug}`);
};

export const fetchGroupDefaults = async (groupCategorySlug) => {
  return await fetchApi(
    `/api/v1/groups/new?group_category_slug=${groupCategorySlug}`,
  );
};
export const deleteGroup = async ({ slug }) => {
  const response = await fetchApi(`/groups/${slug}`, {
    method: "DELETE",
  });

  return response;
};

export const archiveGroup = async ({ slug, archived }) => {
  const response = await fetchApi(`/groups/${slug}/archive`, {
    method: archived ? "DELETE" : "PUT",
  });

  return response;
};

export const createGroup = async ({ body }) => {
  const response = await fetchApi("/groups", {
    method: "POST",
    body,
  });

  return response;
};

export const updateGroup = async ({ groupSlug, body }) => {
  const response = await fetchApi(`/groups/${groupSlug}`, {
    method: "PUT",
    body,
  });

  return response;
};

export function useChangeGroupSubscription(
  options?: Omit<
    UseMutationOptions<
      any,
      unknown,
      { id: string; action: "subscribe" | "unsubscribe" },
      unknown
    >,
    "mutationFn"
  >,
) {
  return useMutation(
    ({ id, action }: { id: string; action: "subscribe" | "unsubscribe" }) =>
      fetchApi(`/api/v1/groups/${id}/subscribe`, {
        method: action === "subscribe" ? "POST" : "DELETE",
      }),
    options,
  );
}

export function useChangeMembershipGroupStatus(
  options?: Omit<
    UseMutationOptions<
      any,
      unknown,
      { id: string; action: "join" | "leave" },
      unknown
    >,
    "mutationFn"
  >,
) {
  return useMutation(
    ({ id, action }: { id: string; action: "join" | "leave" }) =>
      fetchApi(`/api/v1/groups/${id}/join`, {
        method: action === "join" ? "POST" : "DELETE",
      }),
    options,
  );
}
