import React, { useContext } from "react";

type RssItem = {
  title: string;
  url: string;
};

type RssData = {
  headline: string;
  items: RssItem[];
};

type WidgetContextType = {
  w: number;
};

type Props = {
  id?: string;
  preview_data?: RssData;
};

import { WidgetContext } from "components/channels/widgets/WidgetContainer";
import { chunk } from "lodash";
import classNames from "classnames";
import { useParams } from "helpers/tixxt-router";
import { useQuery } from "react-query";

export default function ChannelRssWidget({ id, preview_data }: Props) {
  const params = useParams();
  const { data } = useQuery<{ rss_data: RssData }>(
    `/channels/${params.channel_id}/widgets/${id}`,
    {
      enabled: !!id,
    },
  );
  const rss_data = data?.rss_data || preview_data;
  const { w } = useContext<WidgetContextType>(WidgetContext);
  const maxItems: number = w * 4;
  const limitedItems: RssItem[] = rss_data?.items.slice(0, maxItems) || [];
  const itemsPerColumn: number = Math.ceil(limitedItems.length / w);
  const itemsByColumn: RssItem[][] = chunk(limitedItems, itemsPerColumn);

  return (
    <>
      {rss_data && (
        <div className="widget rss-widget">
          <div
            className={classNames("grid divide-x gap-4", {
              "grid-cols-1": w === 1,
              "grid-cols-2": w === 2,
              "grid-cols-3": w === 3,
            })}
          >
            {itemsByColumn.map((columnItems: RssItem[], n: number) => (
              <ul key={n} className="list-disc p-4 prose">
                {columnItems.map((item: RssItem, index: number) => (
                  <li key={index} className="mx-4">
                    <a
                      href={item.url}
                      title={item.title}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="line-clamp-2 no-underline hover:underline"
                    >
                      {item.title}
                    </a>
                  </li>
                ))}
              </ul>
            ))}
          </div>
        </div>
      )}
    </>
  );
}
