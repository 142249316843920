import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faNewspaper as faRegularNewspaper,
  faEnvelopeOpenText as faRegularEnvelopeOpenText,
  faCalendarCircleUser as faRegularCalendarCircleUser,
  faIdBadge as faRegularIdBadge,
  faUserPen as faRegularUserPen,
  faPenToSquare as faRegularPenToSquare,
  faGears as faRegularGears,
  faEye as faRegularEye,
  faChartUser as faRegularChartUser,
  faScrewdriverWrench as faRegularScrewdriverWrench,
  faGear as faRegularGear,
  faArrowRightFromBracket as faRegularArrowRightFromBracket,
} from "@fortawesome/pro-regular-svg-icons";
import { faBell as faLightBell } from "@fortawesome/pro-light-svg-icons";
import {
  faBell as faDuotoneBell,
  faCircleChevronDown as faDuotoneCircleChevronDown,
} from "@fortawesome/pro-duotone-svg-icons";
import { useSelector } from "react-redux";
import {
  currentMemberSelector,
  groupCategoriesSelector,
  networkSelector,
} from "selectors/environment";
import { map, filter } from "lodash";
import DropDown, { DropDownItem } from "../shared/DropDown";
import UnreadBadge from "components/shared/UnreadBadge";
import { useFeaturesUnreads } from "api/unreads";
import ChatButton from "components/layout/userCentral/ChatButton";
import classNames from "classnames";
import { AvailableUpdatesPayload } from "components/layout/CustomerCentralUpdateCheck";

function NotificationPing({ className }: { className?: string }) {
  return (
    <span className={classNames("absolute top-4 right-4", className)}>
      <span className="animate-ping absolute inline-flex h-2 w-2 rounded-full opacity-75 bg-unread" />
      <span className="rounded-full absolute h-2 w-2 bg-unread" />
    </span>
  );
}

function NotificationDropDownItems({
  unreads,
  className,
}: {
  unreads?:
    | {
        Notifications: number;
        GroupInvites: number;
        Calendars: number;
      }
    | undefined;
  className?: string;
}) {
  return (
    <>
      <DropDownItem
        url="/notifications"
        icon={faRegularNewspaper}
        text={I18n.t("js.activitystreams.userbar.filter.notifications")}
        additional={<UnreadBadge count={unreads?.Notifications} />}
        className={className}
      />
      <DropDownItem
        url="/invites"
        icon={faRegularEnvelopeOpenText}
        text={I18n.t("groups.invites.title")}
        additional={<UnreadBadge count={unreads?.GroupInvites} />}
        className={className}
      />
      <DropDownItem
        url="/appointments/invites"
        icon={faRegularCalendarCircleUser}
        text={I18n.t("calendars.invites.title")}
        additional={<UnreadBadge count={unreads?.Calendars} />}
        className={className}
      />
    </>
  );
}

export default function UserCentral({
  duo,
  availableUpdates,
}: {
  duo?: boolean;
  availableUpdates: AvailableUpdatesPayload;
}) {
  const currentMember = useSelector(currentMemberSelector);
  const network = useSelector(networkSelector);
  const groupCategories = useSelector(groupCategoriesSelector);
  const { data: unreads } = useFeaturesUnreads();

  const unreadCount = unreads
    ? unreads?.GroupInvites + unreads?.Calendars + unreads?.Notifications
    : 0;

  if (!currentMember) return null;

  const administratableGroupCategories = filter(
    groupCategories,
    (cat) => cat.can.administrate,
  );

  return (
    <ul className="user-central flex">
      <li className={"hidden sm:flex"}>
        <DropDown.Toggle
          className="header__link notifications__link relative"
          icon={duo ? faDuotoneBell : faLightBell}
          iconClassName="notifications__icon fa-xl"
          title={I18n.t("js.layouts.user_central.notifications_title")}
          buttonType="normal"
        >
          {unreadCount > 0 && <NotificationPing />}
        </DropDown.Toggle>
        <DropDown>
          <NotificationDropDownItems unreads={unreads} />
        </DropDown>
      </li>

      <li className={"flex"}>
        <button
          className="header__link"
          data-bs-toggle="dropdown"
          title={I18n.t("js.memberships.my_profile")}
          onClick={(e) => e.stopPropagation()}
        >
          <img
            className="menu__profile-image rounded-full w-9"
            src={currentMember.images.medium}
            alt={currentMember.displayName}
          />
          <FontAwesomeIcon
            icon={faDuotoneCircleChevronDown}
            className={"menu__indicator absolute right-2.5 bottom-2.5"}
          />
          {availableUpdates?.length > 0 && (
            <NotificationPing className={"right-2"} />
          )}
        </button>
        <DropDown
          className={"user-central__dropdown-menu max-h-96 overflow-y-auto"}
        >
          <NotificationDropDownItems
            unreads={unreads}
            className={"sm:hidden"}
          />
          <li className="divider sm:hidden" />
          <DropDownItem
            url={`/members/${currentMember.id}`}
            icon={faRegularIdBadge}
            text={I18n.t("js.memberships.my_profile")}
          />
          {currentMember.can.update_profile && (
            <DropDownItem
              url={`/members/${currentMember.id}/edit`}
              icon={faRegularUserPen}
              text={I18n.t("js.layouts.user_central.edit_profile")}
            />
          )}
          {currentMember.can.update_registration && (
            <DropDownItem
              url="/users/edit"
              icon={faRegularPenToSquare}
              text={I18n.t("js.layouts.user_central.edit_registration")}
            />
          )}
          {currentMember?.can.update_profile && (
            <DropDownItem
              url={`/members/${currentMember.id}/settings/edit`}
              icon={faRegularGears}
              text={I18n.t("js.layouts.user_central.edit_membership_settings")}
            />
          )}
          {currentMember && (
            <DropDownItem
              url={`/members/${currentMember.id}/ignored`}
              icon={faRegularEye}
              text={I18n.t("js.memberships.userbar.ignored_members")}
            />
          )}
          {network.config.memberships?.change_requests?.enabled && (
            <DropDownItem
              url={`/change_requests/new`}
              icon={faRegularChartUser}
              text={I18n.t("js.layouts.user_central.change_request")}
            />
          )}

          {network.can.administrate ||
          administratableGroupCategories.length > 0 ||
          network.can.version_and_updates ? (
            <li className="divider" />
          ) : null}

          {network.can.administrate && (
            <DropDownItem
              url="/administration"
              icon={faRegularScrewdriverWrench}
              text={I18n.t("js.layouts.user_central.administration")}
            />
          )}
          {map(administratableGroupCategories, (cat) => (
            <DropDownItem
              key={cat.id}
              url={`/group-categories/${cat.slug}/administration`}
              text={I18n.t("js.layouts.user_central.group_categories", {
                name: cat.plural_name,
              })}
            />
          ))}

          {network.can.version_and_updates && (
            <DropDownItem
              url={`/administration/update-zentrale`}
              icon={faRegularGear}
              text={I18n.t("js.layouts.user_central.versions_and_updates")}
              additional={<UnreadBadge count={availableUpdates?.length} />}
            />
          )}
          <li className="divider" />
          <DropDownItem
            linkStyle="user-central__logout-link !text-danger"
            url="/users/sign_out"
            icon={faRegularArrowRightFromBracket}
            text={I18n.t("js.layouts.user_central.sign_out")}
            method="delete"
            data-turbo="false"
            className={"user-central__logout"}
          />
        </DropDown>
      </li>
      {Preload.current_member?.can.chat ? (
        <li className={"flex chat__link"}>
          <ChatButton duo={duo} />
        </li>
      ) : null}
    </ul>
  );
}
