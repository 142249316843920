import React from "react";
import Contact from "components/appointments/Contact";
import { useNavigate, useParams } from "helpers/tixxt-router";
import { useAppointment } from "components/appointments/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faSpinner } from "@fortawesome/pro-regular-svg-icons";
import PageTitle from "components/layout/PageTitle";

export default function AppointmentContactPage() {
  const { id } = useParams();
  const { data: appointment, isLoading } = useAppointment(id);
  const navigate = useNavigate();

  return (
    <div>
      <PageTitle
        title={I18n.t("js.calendars.appointment.attendees.contact.title")}
      />
      <div className="btn-toolbar mb-10">
        <button
          className="btn btn-light btn-sm"
          onClick={(e) => {
            e.preventDefault();
            navigate(`/appointments/${id}`);
          }}
        >
          <FontAwesomeIcon className="text-primary" icon={faArrowLeft} />
        </button>
      </div>
      {isLoading ? (
        <p>
          <FontAwesomeIcon className="mr-2" icon={faSpinner} spin />
          {I18n.t("js.calendars.appointment.loading")}
        </p>
      ) : (
        appointment && <Contact appointment={appointment} />
      )}
    </div>
  );
}
