import React from "react";
import ToolBar from "components/directory/members/ToolBar";
import { useQuery } from "react-query";
import { stringify } from "query-string";
import { isEmpty, map, toNumber } from "lodash";
import DirectoryPagination from "components/directory/DirectoryPagination";
import MemberRow from "components/directory/members/MemberRow";
import { DirectoryMembership, Pagination } from "components/directory/types";
import useDirectoryFilter from "../hooks/useDirectoryFilter";

type MemberData = {
  consumer_count: number;
  entries: DirectoryMembership[];
  pagination: Pagination;
};

export default function MemberListView() {
  const { filter, currentPage } = useDirectoryFilter();

  const { data, isLoading } = useQuery<MemberData, Error>(
    `/directory/members.json?${stringify(filter)}`,
  );

  return (
    <div className="flex flex-col gap-y-4">
      <ToolBar isLoading={isLoading} filter={filter} />
      <DirectoryPagination
        currentPage={toNumber(currentPage)}
        pagination={data && data.pagination}
        isLoading={isLoading}
      >
        {data && !isEmpty(data?.entries)
          ? map(data.entries, (member) => (
              <MemberRow key={member.id} {...member} />
            ))
          : I18n.t("js.directory.empty_collection")}
      </DirectoryPagination>
    </div>
  );
}
