import React from "react";
import PageTitle, { Subtitle } from "components/layout/PageTitle";
import MessagesList from "components/messages/MessagesList";
import { useSelector } from "react-redux";
import { get } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenLine } from "@fortawesome/pro-regular-svg-icons";
import classNames from "classnames";

export function InboxToolbar({ className }: { className?: string }) {
  const sendDirectMessages = useSelector((state) =>
    get(state, "currentMember.can.send_direct_messages"),
  );
  if (!sendDirectMessages) return null;

  return (
    <div className={classNames("btn-toolbar justify-end", className)}>
      <a className="btn btn-primary" href="/messages/new">
        <FontAwesomeIcon icon={faPenLine} className={"mr-2"} />
        {I18n.t("js.messages.new.new_message")}
      </a>
    </div>
  );
}

export default function InboxPage() {
  return (
    <>
      <PageTitle title={I18n.t("js.messages.module_name")} />
      <Subtitle className={"mb-3 mt-8"}>
        {I18n.t("js.messages.index.title")}
      </Subtitle>
      <InboxToolbar className={"mb-10"} />
      <MessagesList />
    </>
  );
}
