import React from "react";
import ReactSelect, { MultiValue, SingleValue } from "react-select";

import Label from "components/shared/form/Label";
import { Control, Controller } from "react-hook-form";
import { ValueType } from "components/shared/DirectoryPicker/types";
import Hint from "components/shared/fields/Hint";

type SelectArgs = {
  name: string;
  label?: string;
  multi?: boolean;
  required?: boolean | string;
  control?: Control<any>;
  options: { value: string; label: string }[];
  placeholder?: string;
  isSearchable?: boolean;
  hint?: string;
  valuesToApi?: (
    formValues: MultiValue<ValueType> | SingleValue<ValueType>,
  ) => any;
};
export default function Select({
  label,
  name,
  multi = false,
  required = false,
  options,
  control,
  placeholder,
  isSearchable = true,
  hint,
}: SelectArgs) {
  return (
    <div className="control-group">
      {label && <Label label={label} required={!!required} />}
      <div className="controls remove-input-txt-border">
        <Controller
          name={name}
          control={control}
          rules={{ required: required }}
          render={({ field }) => (
            <>
              <ReactSelect
                closeMenuOnSelect
                isMulti={multi}
                options={options}
                placeholder={placeholder}
                isSearchable={isSearchable}
                noOptionsMessage={() => I18n.t("js.plugins.select2.no_match")}
                {...field}
                className="form-select-container"
                classNamePrefix="form-select"
                unstyled
              />
              {hint && <Hint hint={hint} />}
            </>
          )}
        />
      </div>
    </div>
  );
}
