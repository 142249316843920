import PropTypes from "prop-types";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
  faCheck,
  faCheckDouble,
} from "@fortawesome/pro-solid-svg-icons";

export const getIcon = (deliveryStatus) => {
  switch (deliveryStatus) {
    case "pushed":
      return <FontAwesomeIcon icon={faSpinner} className="fa-sm fa-spin" />;
    case "persisted":
      return <FontAwesomeIcon icon={faCheck} className="fa-sm" />;
    case "received":
      return <FontAwesomeIcon icon={faCheckDouble} className="fa-sm" />;
    case "read":
      return <FontAwesomeIcon icon={faCheckDouble} className="fa-sm" />;
    default:
      return null;
  }
};

export const getTitle = (deliveryStatus) => {
  switch (deliveryStatus) {
    case "pushed":
      return "wird gespeichert";
    case "persisted":
      return "gespeichert";
    case "received":
      return "zugestellt";
    case "read":
      return "gelesen";
    default:
      return null;
  }
};

const MessageDeliveryStatus = ({ deliveryStatus }) => {
  return (
    <span
      className={`chat__delivery_status chat__delivery_status--${deliveryStatus}`}
      title={getTitle(deliveryStatus)}
    >
      {getIcon(deliveryStatus)}
    </span>
  );
};

MessageDeliveryStatus.propTypes = {
  deliveryStatus: PropTypes.oneOf(["pushed", "persisted", "received", "read"]),
};

export default MessageDeliveryStatus;
