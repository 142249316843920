import { useSearchParams } from "helpers/tixxt-router";
import { Filter } from "components/directory/types";

export default function useDirectoryFilter(): {
  filter: Filter;
  currentPage: string;
} {
  const [searchParams] = useSearchParams();

  const currentPage = searchParams.get("page") || "1";

  function getCurrentFilter() {
    const currentFilter = {};

    searchParams.forEach((value, key) => (currentFilter[key] = value));

    return currentFilter;
  }

  return { filter: getCurrentFilter(), currentPage };
}
