import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons";

export default function PageLoading() {
  return (
    <div className="flex gap-2 items-center">
      <FontAwesomeIcon icon={faSpinner} spin />
      {I18n.t("js.loading")}
    </div>
  );
}
