import React from "react";
import AppointmentForm from "components/appointments/form/AppointmentForm";
import { useSearchParams } from "helpers/tixxt-router";
import PageTitle from "components/layout/PageTitle";

export default function AppointmentNewPage() {
  const [searchParams] = useSearchParams();
  const date = searchParams.get("date");
  const calendarId = searchParams.get("calendar_id");

  return (
    <>
      <PageTitle title={I18n.t("js.calendars.new_appointment")} />
      <AppointmentForm
        date={date ? { start: date, all_day: true } : null}
        calendarId={calendarId}
      />
    </>
  );
}
