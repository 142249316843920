import { truncate } from "lodash";
import React, { useEffect, useState } from "react";

export default function AppointmentDescription({
  description,
  isRendered,
  translated,
}: {
  description: string;
  isRendered: boolean;
  translated?: boolean;
}) {
  const [truncateDescription, setTruncateDescription] = useState(true);

  const handleReadMore = (e) => {
    e.preventDefault();
    setTruncateDescription(false);
  };

  function truncateText(text: string) {
    return truncate(text, {
      length: 500,
      omission: `...<a href="#" class="btn-link" id="read-more-link">${I18n.t(
        "activitystreams.more",
      )}</a>`,
    });
  }

  useEffect(() => {
    if (truncateDescription) {
      const link = document.getElementById("read-more-link");
      if (link) {
        link.addEventListener("click", handleReadMore as EventListener);
      }
      return () => {
        if (link) {
          link.removeEventListener("click", handleReadMore as EventListener);
        }
      };
    }
  }, [truncateDescription]);

  const maybeTruncatedDescription = truncateDescription
    ? truncateText(description)
    : description;

  return (
    <>
      {isRendered ? (
        <div
          className="detail-description prose prose-sm"
          dangerouslySetInnerHTML={{
            __html: maybeTruncatedDescription,
          }}
        />
      ) : (
        <p className="whitespace-pre overflow-auto">
          {maybeTruncatedDescription}
        </p>
      )}
      {translated && (
        <span style={{ color: "grey", fontSize: "x-small" }}>(translated)</span>
      )}
    </>
  );
}
