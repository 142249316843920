import { Calendar } from "../../../@types/appointments";
import { stringify } from "query-string";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarCirclePlus } from "@fortawesome/pro-regular-svg-icons";
import { isEmpty, map } from "lodash";
import React, { ReactNode } from "react";
import CalendarListItem from "components/appointments/calendars/CalendarListItem";

export default function CalendarList({
  title,
  calendars,
  renderActions,
  addCalendar,
}: {
  title: string;
  calendars: Calendar[];
  renderActions: (calendar: Calendar | undefined) => ReactNode[];
  addCalendar?: { type: string; parent_id?: string };
}) {
  return (
    <div className="context-calendars my-4">
      <div className="flex gap-4 items-baseline my-1">
        <h3 className="font-semibold">{title}</h3>
        {addCalendar && (
          <a
            className="text-lg"
            href={`/calendars/new?${stringify(addCalendar)}`}
            data-bs-toggle="tooltip"
            data-bs-original-title={I18n.t("calendars.add_calendar")}
            title={I18n.t("calendars.add_calendar")}
          >
            <FontAwesomeIcon icon={faCalendarCirclePlus} />
          </a>
        )}
      </div>
      <ul className="calendars divide-neutral divide-y unstyled border border-neutral rounded">
        {isEmpty(calendars) ? (
          <div className="px-3 py-2">
            {I18n.t("js.calendars.calendar_manager.no_calendars_listed")}
          </div>
        ) : (
          map(calendars, (calendar) => {
            return (
              <CalendarListItem
                key={calendar.id}
                calendar={calendar}
                actions={renderActions}
              />
            );
          })
        )}
      </ul>
    </div>
  );
}
