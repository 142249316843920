import React from "react";
import createInlineMentionPlugin from "components/activitystreams/composer/inlineMentionPlugin";
import createInlineTagPlugin from "components/activitystreams/composer/inlineTagPlugin";
import createToolbarPlugin from "components/shared/markdownEditor/editorPlugins/toolbarPlugin";
import createMarkdownPlugin from "components/shared/markdownEditor/editorPlugins/markdownPlugin";
import createUndoPlugin from "components/shared/markdownEditor/editorPlugins/undoPlugin";
import createShortcutsPlugin from "components/shared/markdownEditor/editorPlugins/shortcutsPlugin";
import createCounterPlugin from "@draft-js-plugins/counter";
import createLinkifyPlugin from "@draft-js-plugins/linkify";
import createBlockBreakoutPlugin from "draft-js-block-breakout-plugin";
import createEmojiPlugin, { defaultTheme } from "@draft-js-plugins/emoji";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFaceSmile } from "@fortawesome/pro-regular-svg-icons";

interface withPluginsTypes {
  [key: PropertyKey]: unknown;
}

function withPlugins(BaseComponent) {
  return class HOC extends React.Component<withPluginsTypes> {
    plugins: Array<unknown>;
    pluginComponents: Record<PropertyKey, unknown>;

    constructor(props: withPluginsTypes) {
      super(props);

      const mentionPlugin = createInlineMentionPlugin();
      const tagPlugin = createInlineTagPlugin();
      const [toolbarPlugin, Toolbar] = createToolbarPlugin();
      const markdownPlugin = createMarkdownPlugin();
      const undoPlugin = createUndoPlugin();
      const counterPlugin = createCounterPlugin();
      const linkifyPlugin = createLinkifyPlugin();
      const blockBreakoutPlugin = createBlockBreakoutPlugin();
      const shortcutsPlugin = createShortcutsPlugin();
      const emojiPlugin = createEmojiPlugin({
        useNativeArt: true,
        selectButtonContent: <FontAwesomeIcon icon={faFaceSmile} />,
        theme: {
          ...defaultTheme,
          emojiSelectButton: "btn btn-sm btn-light",
          emojiSelectButtonPressed: "btn btn-sm btn-light active",
        },
      });

      this.plugins = [
        mentionPlugin.inlineMentionPlugin,
        tagPlugin.inlineTagPlugin,
        toolbarPlugin,
        markdownPlugin,
        undoPlugin,
        counterPlugin,
        linkifyPlugin,
        blockBreakoutPlugin,
        shortcutsPlugin,
        emojiPlugin,
      ];

      this.pluginComponents = {
        InlineMentionSuggestions: mentionPlugin.InlineMentionSuggestions,
        InlineTagSuggestions: tagPlugin.InlineTagSuggestions,
        Toolbar,
        CharCounter: counterPlugin.CharCounter,
        WordCounter: counterPlugin.WordCounter,
        LineCounter: counterPlugin.LineCounter,
        UndoButton: undoPlugin.UndoButton,
        RedoButton: undoPlugin.RedoButton,
        EmojiSuggestions: emojiPlugin.EmojiSuggestions,
        EmojiSelect: emojiPlugin.EmojiSelect,
      };
    }

    render() {
      return (
        <BaseComponent
          plugins={this.plugins}
          pluginComponents={this.pluginComponents}
          {...this.props}
        />
      );
    }
  };
}

export default withPlugins;
