import React from "react";
import { Appointment } from "../../../@types/appointments";
import { isBefore, isSameDay } from "date-fns";
import { filter, find, includes, isEmpty, map } from "lodash";
import { TZDate } from "@date-fns/tz";
import { useCalendars } from "components/appointments/api";
import AppointmentLocation from "components/appointments/details/AppointmentLocation";
import AppointmentDate from "components/appointments/details/AppointmentDate";
import AppointmentMeeting from "components/appointments/details/AppointmentMeeting";
import AppointmentEventFiles from "components/appointments/details/AppointmentEventFiles";
import AppointmentEventDetails from "components/appointments/details/AppointmentEventDetails";
import AppointmentParticipations from "components/appointments/details/AppointmentParticipations";
import formatDate from "helpers/formatDate";
import AppointmentDescription from "components/appointments/details/AppointmentDescription";

export default function AppointmentDetails({
  isRecurring,
  refetch,
  translated,
  ...appointment
}: Appointment & {
  isRecurring: boolean;
  refetch: () => void;
  translated?: boolean;
}) {
  const { data: calendars } = useCalendars();
  const appointmentStart = new Date(appointment.date.start);
  const today = new Date();
  const appointmentEnd = appointment.date.end
    ? new Date(appointment.date.end)
    : null;

  const isPast =
    isBefore(appointmentStart, today) &&
    (isEmpty(appointmentEnd) ||
      (appointmentEnd && isBefore(appointmentEnd, today))) &&
    (!isRecurring ||
      (appointment.recurrence_pattern &&
        appointment.recurrence_pattern.recurrence_end_time &&
        isBefore(
          new TZDate(
            appointment.recurrence_pattern.recurrence_end_time,
            appointment.date.time_zone,
          ),
          new TZDate(appointment.date.time_zone),
        )));

  const isDifferentTimeZone =
    !appointment.date.all_day &&
    Intl.DateTimeFormat().resolvedOptions().timeZone !==
      appointment.date.time_zone;

  const appointmentCalendars = filter(calendars, (cal) =>
    includes(appointment.calendar_ids, cal.id),
  );

  const formattedStart = formatDate(
    appointmentStart,
    appointment.date.all_day ? "eeee, MMMM d y" : "eeee, MMMM d y HH:mm",
  );

  const formattedEnd = appointmentEnd
    ? isSameDay(appointmentStart, appointmentEnd)
      ? formatDate(appointmentEnd, "HH:mm")
      : formatDate(
          appointmentEnd,
          appointment.date.all_day ? "eeee, MMMM d y" : "eeee, MMMM d y HH:mm",
        )
    : null;

  const myParticipation = find(appointment.participants, { id: "me" });

  return (
    <div className="sm:grid sm:grid-cols-3 gap-6 pb-4">
      <div className="sm:col-span-2 order-1">
        {appointment.canceled && (
          <div className="alert alert-error">
            {I18n.t("js.calendars.appointment.canceled")}
          </div>
        )}
        {isPast && (
          <div className="alert alert-info">
            {Preload.current_network.config.appointments.opt_retroactively
              ? I18n.t("js.calendars.appointment_in_past_with_opt")
              : I18n.t("js.calendars.appointment_in_past_without_opt")}
          </div>
        )}
        {isDifferentTimeZone && (
          <div className="alert alert-info">
            {I18n.t("js.calendars.appointment.original_timezone", {
              timezone: `${appointment.date.time_zone
                .replace(/\//g, ", ")
                .replace(/_/g, " ")} (UTC${formatDate(
                TZDate.tz(appointment.date.time_zone),
                "xxx",
              )})`,
            })}
          </div>
        )}
        <div className="border-box bg-primary/10 border-primary/25 px-2 py-4 text-sm space-y-4 break-words">
          <div className="detail-calendars">
            <ol className="calendar-list flex flex-wrap gap-2 mb-8">
              {map(appointmentCalendars, (cal) => (
                <li className="flex gap-1 items-center" key={cal.id}>
                  <i
                    className="w-4 h-4 block rounded-full"
                    style={{ backgroundColor: `#${cal.color}` }}
                  />
                  {cal.name}
                </li>
              ))}
            </ol>
          </div>
          <AppointmentDate
            date={appointment.date}
            appointmentStart={appointmentStart}
            appointmentEnd={appointmentEnd}
            isRecurring={isRecurring}
            recurrence_pattern={appointment.recurrence_pattern}
            next_occurrence_date={appointment.next_occurrence_date}
            next_occurrence_url={appointment.next_occurrence_url}
            formattedEnd={formattedEnd}
            formattedStart={formattedStart}
          />
          <AppointmentLocation {...appointment.location} />
          {appointment.meeting && appointment.meeting.join_url && (
            <AppointmentMeeting
              meeting={appointment.meeting}
              formattedEnd={formattedEnd}
              formattedStart={formattedStart}
              appointmentName={appointment.name}
              canStartMeeting={appointment.can.start_meeting}
              appointmentId={appointment.id}
            />
          )}
          {appointment.teams_configuration.join_url &&
            myParticipation?.membership.upn && (
              <div className="text-center">
                <a
                  href={appointment.teams_configuration.join_url}
                  className="btn btn-light"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  <img
                    src={Assets["teams_16x2.png"]}
                    className="w-4 inline mr-1"
                  />
                  <span>
                    {I18n.t("js.calendars.appointment.teams.join_link")}
                  </span>
                </a>
              </div>
            )}
          {appointment.teams_configuration.teams_event &&
            myParticipation?.membership.show_add_upn_link && (
              <div className="text-center">
                <a
                  href="/users/edit"
                  className="btn btn-warning"
                  target="_blank"
                  rel="nofollow noopener"
                >
                  <img
                    src={Assets["teams_16x2.png"]}
                    className="w-4 inline mr-1"
                  />
                  <span>
                    {I18n.t("js.calendars.appointment.teams.oauth_link")}
                  </span>
                </a>
              </div>
            )}
          <AppointmentDescription
            description={
              appointment.description_rendered || appointment.description
            }
            isRendered={!isEmpty(appointment.description_rendered)}
            translated={translated}
          />
        </div>
        {!isEmpty(appointment.event_files) && (
          <AppointmentEventFiles eventFiles={appointment.event_files} />
        )}
        <AppointmentEventDetails
          category={appointment.category?.toString() || null}
          contacts={appointment.contacts}
          attendeeConfig={appointment.attendee_configuration}
          applicationConfig={appointment.application_configuration}
          attendingParticipantsCount={appointment.attending_participants_count}
          teamsParticipantsCount={
            appointment.teams_configuration.teams_only_participants_count
          }
          organizer={appointment.organizer}
          consultants={appointment.consultants}
          createdAt={appointment.created_at}
          updatedAt={appointment.updated_at}
          genericAuthor={appointment.generic_author}
          author={appointment.author}
          lastAuthor={appointment.last_author}
          translated={translated}
        />
      </div>
      <div className="flex flex-col gap-6 sm:block sm:col-span-1 -order-1 sm:order-2 sm:space-y-6">
        {appointment.event_image && (
          <img
            className="flex-1"
            src={appointment.event_image.large_thumb}
            alt="Event"
          />
        )}
        <AppointmentParticipations
          appointmentId={appointment.id}
          canShowAttendees={appointment.can.show_attendees}
          participants={appointment.participants}
          invitedConsumers={appointment.invited_consumers}
          teamsOnlyParticipantsCount={
            appointment.teams_configuration.teams_only_participants_count
          }
          canOpt={appointment.can.opt}
          myInvite={appointment.my_invite}
          myParticipation={myParticipation}
          participationLocked={
            appointment.attendee_configuration.participation_locked
          }
          totalAttendeesExceeded={
            appointment.total_attendees_enforced_and_exceeded
          }
          showRecurringDecisionModal={
            appointment.type === "virtual_appointment"
          }
          refetch={refetch}
        />
      </div>
    </div>
  );
}
