import React, { ReactNode } from "react";
import moment from "moment";
import { isEmpty, get } from "lodash";

import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faRotate } from "@fortawesome/pro-regular-svg-icons";
import { useAcceptInvite, useDeclineInvite } from "components/invites/api";

type AppointmentInviteMetaArgs = {
  status: string;
  accept: () => void;
  decline: () => void;
  canOptRetroactively: boolean;
  totalAttendeesEnforcedAndExceeded: boolean;
  inviteStatus: string;
  participation_locked: boolean;
};
// Reused in bitkom custom
export function AppointmentInviteMeta({
  status,
  accept,
  decline,
  canOptRetroactively,
  inviteStatus,
  totalAttendeesEnforcedAndExceeded,
  participation_locked,
}: AppointmentInviteMetaArgs) {
  const buttons = totalAttendeesEnforcedAndExceeded ? (
    <div className="muted max-w-xs">
      {I18n.t(`js.calendars.appointment.total_attendees_exceeded`)}
    </div>
  ) : participation_locked ? (
    <div className="muted max-w-xs">
      {I18n.t(`js.calendars.appointment.participation_locked`)}
    </div>
  ) : (
    <div className="btn-group w-full align-left">
      <button
        onClick={accept}
        className="btn btn-success"
        disabled={!!totalAttendeesEnforcedAndExceeded}
      >
        <i className="fa fa-plus mr-1" />
        {I18n.t("js.invites.actions.accept")}
      </button>
      <button onClick={decline} className="btn btn-danger">
        <i className="fa fa-xmark mr-1" />
        {I18n.t("js.invites.actions.reject")}
      </button>
    </div>
  );

  const hint = (
    <div className="appointment-invite-reaction">
      {status !== "open"
        ? I18n.t(`js.calendars.appointments.invite.${status}`)
        : null}
    </div>
  );

  switch (inviteStatus) {
    case "open":
      return status === inviteStatus ? buttons : hint;
    case "accepted":
    case "declined":
      return status === inviteStatus ? hint : null;
    case "expired":
      return status === "open" ? (canOptRetroactively ? buttons : null) : hint;
    case "obsolete":
      return status === inviteStatus ? hint : null;
    default:
      return null;
  }
}

type AppointmentInviteDateArgs = {
  date: {
    all_day: boolean;
    end: string;
    same_day: boolean;
    start: string;
    id?: string;
  };
  frequency?: string;
};
// Reused in bitkom custom
export function AppointmentInviteDate({
  date,
  frequency,
}: AppointmentInviteDateArgs) {
  return (
    <div className="appointment-date">
      {frequency
        ? I18n.t(`js.time.formats.frequencies.${frequency}`) + " "
        : null}
      <time>
        {moment(date.start).format(
          I18n.t(
            date.all_day
              ? "js.time.formats.medium_date"
              : "js.time.formats.medium_date_and_time",
          ),
        )}
      </time>
      {date.end ? (
        <time>
          {" - " +
            moment(date.end).format(
              I18n.t(
                date.all_day
                  ? "js.time.formats.medium_date"
                  : date.same_day
                    ? "js.time.formats.medium_time"
                    : "js.time.formats.medium_date_and_time",
              ),
            )}
        </time>
      ) : null}
    </div>
  );
}

type Invitable = {
  calendar_ids?: string[];
  date: AppointmentInviteDateArgs["date"];
  id: string;
  name: string;
  status: string;
  frequency?: string;
  total_attendees_enforced_and_exceeded?: number;
  participation_locked: boolean;
};

type AppointmentInviteArgs = {
  id: string;
  status: string;
  inviteStatus: string;
  invitable_type: string;
  author: {
    id: string;
    name: string;
  } | null;
  invitable: Invitable;
  renderDate: (invitable: Invitable) => ReactNode;
  reloadInvites: () => void;
  renderMeta: (props: {
    inviteStatus: string;
    status: string;
    decline: () => void;
    accept: () => void;
    canOptRetroactively: boolean;
    totalAttendeesEnforcedAndExceeded?: number;
    participation_locked: boolean;
  }) => ReactNode;
};

export default function AppointmentInvite({
  invitable,
  invitable_type,
  author,
  status,
  inviteStatus,
  id,
  renderDate,
  renderMeta,
  reloadInvites,
}: AppointmentInviteArgs) {
  const network = Tixxt.currentNetwork;
  const { mutate: declineInvite } = useDeclineInvite({
    onSuccess: () => reloadInvites(),
  });

  const { mutate: acceptInvite } = useAcceptInvite({
    onSuccess: () => {
      reloadInvites();
    },
  });

  const canOptRetroactively = get(network, [
    "config",
    "appointments",
    "opt_retroactively",
  ]);

  return (
    <div className="invite-container grid grid-cols-[1fr] sm:grid-cols-[30px_1fr_1fr] md:grid-cols-[30px_1.5fr_1fr] py-6 px-1.5 gap-2 sm:gap-3 md:gap-5">
      {invitable_type === "RecurringAppointment" ? (
        <span className={"fa-stack hidden sm:block -ml-1.5"}>
          <FontAwesomeIcon icon={faCalendar} className={"fa-stack-2x"} />
          <FontAwesomeIcon
            icon={faRotate}
            className={"fa-stack-1x top-2 !h-[0.75em]"}
          />
        </span>
      ) : (
        <FontAwesomeIcon
          icon={faCalendar}
          size={"2xl"}
          className={"hidden sm:block"}
        />
      )}

      <div className="invite-info grow">
        {!isEmpty(invitable) ? (
          <div>
            <div
              className={`appointment-name text-lg -mt-1 ${invitable.status}`}
            >
              <a href={`/appointments/${invitable.id}`}>{invitable.name}</a>
            </div>
            {renderDate(invitable)}
          </div>
        ) : null}
        {!isEmpty(author) ? (
          <div className="invite-author">
            {I18n.t("js.invites.invited_by") + " "}
            <a href={`/members/${author.id}`}>{author.name}</a>
          </div>
        ) : null}
      </div>
      <div
        // When total_attendees_enforced_and_exceeded is true, text is displayed instead of buttons.
        // This should be able to shrink, but the buttons not.
        className={classnames(
          "appointment-invite-interactions col-span-2 sm:col-span-1",
          {
            "shrink-0": !invitable.total_attendees_enforced_and_exceeded,
          },
        )}
      >
        {renderMeta({
          inviteStatus: inviteStatus,
          status: status,
          decline: () => declineInvite({ inviteId: id }),
          accept: () => acceptInvite({ inviteId: id }),
          canOptRetroactively: canOptRetroactively,
          totalAttendeesEnforcedAndExceeded:
            invitable.total_attendees_enforced_and_exceeded,
          participation_locked: invitable.participation_locked,
        })}
      </div>
    </div>
  );
}
