import { LayoutType } from "components/channels/types";

// class helpers

export function getWidgetContentHeight(w: number) {
  return (
    {
      1: "h-[21rem]",
      2: "h-[42rem]",
      3: "h-[63rem]",
    }[w] + " sm:h-[21rem]"
  );
}

export function getWidgetContentColumns(w: number) {
  return {
    1: "sm:grid-cols-1",
    2: "sm:grid-cols-2",
    3: "sm:grid-cols-3",
  }[w];
}

export function getWidgetWidthByType(type: LayoutType) {
  return {
    full: ["w-full"],
    "half-1-2": ["w-1/3", "w-2/3"],
    "half-2-1": ["w-2/3", "w-1/3"],
    third: ["w-1/3", "w-1/3", "w-1/3"],
  }[type];
}

export function getWidgetColspan(w: number) {
  return {
    1: "sm:col-span-1",
    2: "sm:col-span-2",
    3: "sm:col-span-3",
  }[w];
}

export function getWidgetContainerResponsiveHeight(w: number) {
  return (
    {
      1: "max-sm:h-[24rem]",
      2: "max-sm:h-[48rem]",
      3: "max-sm:h-[72rem]",
    }[w] + " sm:h-[24rem]"
  );
}

// type helpers

export function getWidgetColspanType(type: LayoutType) {
  return {
    full: ["colspan3"],
    "half-1-2": ["col1", "colspan2"],
    "half-2-1": ["colspan2", "col1"],
    third: ["col1", "col2", "col3"],
  }[type];
}

export function getWidgetColumnIndex(type: LayoutType, colspan: string) {
  return {
    full: { colspan3: 0 },
    "half-1-2": { col1: 0, colspan2: 1 },
    "half-2-1": { colspan2: 0, col1: 1 },
    third: { col1: 0, col2: 1, col3: 2 },
  }[type][colspan];
}
