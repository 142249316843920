import React from "react";
import PageTitle, { Subtitle } from "components/layout/PageTitle";
import TranslationDropDown, {
  ORIGINAL_LANGUAGE_SIGNIFIER,
} from "components/shared/TranslationDropDown";

import { useState } from "react";

import MessageDetails from "components/messages/message";
import Loading from "components/shared/Loading";

export default function ShowMessagePage() {
  const [messageLang, setMessageLang] = useState<string | undefined>();
  const [isTranslatingIndicator, setIsTranslatingIndicator] = useState(false);

  const handleLanguageSelect = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    language: string,
  ) => {
    if (language === ORIGINAL_LANGUAGE_SIGNIFIER) {
      setMessageLang(undefined);
    } else {
      setMessageLang(language);
    }
  };

  return (
    <>
      <PageTitle title={I18n.t("js.messages.message.title")}>
        <>
          <TranslationDropDown
            handleLanguageSelect={handleLanguageSelect}
            object_language={Preload.current_network.language}
          />
          {isTranslatingIndicator && <Loading />}
        </>
      </PageTitle>

      <Subtitle className={"mb-3 mt-8"}>
        {I18n.t("js.messages.message.history")}
      </Subtitle>
      <MessageDetails
        messageLang={messageLang}
        setIsTranslatingIndicator={setIsTranslatingIndicator}
      />
    </>
  );
}
