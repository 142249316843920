import {
  GroupSearchDisabled,
  MemberSearchDisabled,
  MembershipRole,
  OptionGroup,
  PermissionPickableWithDisabled,
} from "components/shared/DirectoryPicker/types";
import { throwBadType } from "components/shared/DirectoryPicker/optionRendering";
import { flatMap, map } from "lodash";
import { GroupAdminEntry } from "components/directory/api";

export function groupOptions(
  options: PermissionPickableWithDisabled[],
): OptionGroup[] {
  const membersLabel = I18n.t("js.permission_picker.members.group_label");
  const systemLabel = I18n.t("js.permission_picker.system_roles.group_label");
  const networkMembershipRolesLabel = I18n.t(
    "js.permission_picker.network_membership_roles.group_label",
  );
  const result: { [label: string]: PermissionPickableWithDisabled[] } = {};

  options.forEach((entry) => {
    switch (entry.type) {
      case "member": {
        if (!result[membersLabel]) result[membersLabel] = [];
        result[membersLabel].push(entry);
        break;
      }
      case "group": {
        const pluralName = entry.category.plural_name;
        if (!result[pluralName]) result[pluralName] = [];
        result[pluralName].push(entry);
        break;
      }
      case "group_admin": {
        const pluralName = entry.category.plural_name;
        if (!result[pluralName]) result[pluralName] = [];
        result[pluralName].push(entry);
        break;
      }
      case "system_role": {
        if (!result[systemLabel]) result[systemLabel] = [];
        result[systemLabel].push(entry);
        break;
      }
      case "membership_role": {
        if (!result[networkMembershipRolesLabel])
          result[networkMembershipRolesLabel] = [];
        result[networkMembershipRolesLabel].push(entry);
        break;
      }
      default:
        throwBadType(entry);
    }
  });

  return map(result, (options, label) => ({ label, options }));
}

export function expandMembershipRoles(
  groupedEntries: OptionGroup[],
): OptionGroup[] {
  return map(groupedEntries, (group: OptionGroup) => {
    const options = flatMap(
      group.options,
      (option): PermissionPickableWithDisabled[] => {
        return option.type == "group"
          ? [
              option,
              {
                ...option,
                type: "group_admin",
              } as GroupAdminEntry,
              ...map(
                option.membership_roles,
                (role): MembershipRole => ({
                  id: role.id,
                  type: "membership_role",
                  name: role.name,
                  parent_id: role.id,
                  parent_type: "group",
                }),
              ),
            ]
          : [option];
      },
    );
    return { ...group, options };
  });
}

export function disabledOptions(
  inputValue: string,
  endpoint: string,
): PermissionPickableWithDisabled[] {
  if (inputValue.length > 0) return [];

  const disabledOptions: PermissionPickableWithDisabled[] = [];
  const memberSearchDisabled: MemberSearchDisabled = {
    id: "member_disabled",
    type: "member",
    disabled: true,
  };
  disabledOptions.push(memberSearchDisabled);

  if (endpoint != "/directory/members") {
    const groupSearchDisabled: GroupSearchDisabled = {
      id: "group_disabled",
      type: "group",
      disabled: true,
      category: {
        plural_name: "Gruppen",
      },
      membership_roles: [],
    };
    disabledOptions.push(groupSearchDisabled);
  }

  return disabledOptions;
}
