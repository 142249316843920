import React from "react";
import moment from "moment/moment";
import { Control, Controller, useWatch } from "react-hook-form";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import Label from "components/shared/form/Label";
import { compact, isEmpty, map } from "lodash";
import DateTimePicker from "components/shared/form/fields/DateTimePicker";
import ReactSelect from "react-select";
import classNames from "classnames";
import PartialToggle from "components/appointments/form/PartialToggle";

type DateSelectArgs = {
  name: string;
  label?: string;
  control: Control<any>;
  required?: boolean;
};
export default function DateRangeSelect({
  name,
  label,
  required = false,
  control,
}: DateSelectArgs) {
  return (
    <div className="control-group">
      {label && <Label label={label} required={required} />}
      <div className="controls border-box">
        <Controller
          name={name}
          control={control}
          render={({ field }) => renderDateRangeSelect({ field, control })}
        />
      </div>
    </div>
  );
}

function renderDateRangeSelect({ field, control }) {
  const { all_day, start, time_zone, end } = useWatch({
    control,
    name: "date",
  });

  function handleChange(value: { [key: string]: string | boolean | null }) {
    field.onChange({
      ...field.value,
      ...value,
    });
  }

  const selectOptions = compact(
    map(moment.tz.names(), (zone) => {
      const invalid = zone.startsWith("Etc/") || /^[A-Z0-9+-]+$/.test(zone);

      if (!invalid) {
        return {
          value: zone,
          label: `${zone.replace(/\//g, ", ").replace(/_/g, " ")} (${moment.tz
            .zone(zone)
            ?.abbr(moment.tz(start, time_zone).valueOf())})`,
        };
      } else {
        return null;
      }
    }),
  );

  return (
    <div className="flex flex-col p-3 gap-y-3">
      <DateTimePicker
        label={I18n.t("js.calendars.date_range.start_date.label")}
        date={start}
        showTimePicker={!all_day}
        showTimezone
        timeZone={time_zone}
        onChange={(value: string) => handleChange({ start: value })}
      />
      <div className="control-group">
        <div className="controls">
          <label className="checkbox align-baseline">
            <input
              type="checkbox"
              checked={all_day}
              onChange={(e) => handleChange({ all_day: e.target.checked })}
            />
            {I18n.t("js.calendars.date_range.all_day")}
          </label>
        </div>
      </div>
      <PartialToggle
        onToggle={(showPartial) => {
          handleChange({
            end: showPartial
              ? null
              : field.value.end || moment(start).add(1, "hour").format(),
          });
        }}
        label={(showPartial) =>
          showPartial
            ? I18n.t("js.calendars.date_range.remove_end")
            : I18n.t("js.calendars.date_range.set_end.link")
        }
        hideIcon={faXmark}
        iconClassName={(showPartial) =>
          classNames({ "text-danger": showPartial })
        }
        shouldShowPartial={!isEmpty(field.value.end)}
      >
        <DateTimePicker
          label={I18n.t("js.calendars.date_range.end")}
          date={end}
          showTimePicker={!all_day}
          onChange={(value: string) => handleChange({ end: value })}
        />
      </PartialToggle>

      {!all_day && (
        <>
          <PartialToggle
            label={I18n.t("js.calendars.date_range.timezone.select_label")}
          >
            <div className="control-group">
              <label className="control-label">
                {I18n.t("js.calendars.date_range.timezone.label")}
              </label>
              <div className="controls remove-input-txt-border">
                <ReactSelect
                  closeMenuOnSelect
                  noOptionsMessage={() => I18n.t("js.plugins.select2.no_match")}
                  value={{
                    label: `${time_zone} (${moment
                      .tz(start, time_zone)
                      .format("z")})`,
                    value: time_zone,
                  }}
                  options={selectOptions}
                  onChange={(selectedOption) =>
                    handleChange({ time_zone: selectedOption?.value })
                  }
                  className="form-select-container"
                  classNamePrefix="form-select"
                  unstyled
                />
              </div>
            </div>
          </PartialToggle>
        </>
      )}
    </div>
  );
}
