import React from "react";
import MemberRow, { Member } from "../shared/MemberRow";
import { faGift } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export type BirthdayWidgetProps = {
  headline?: string;
  your_birthday?: boolean;
  show_age?: boolean;
  memberships: Member[];
};

const BirthdayWidget: React.FC<BirthdayWidgetProps> = ({
  headline,
  your_birthday,
  show_age,
  memberships,
}) => {
  return (
    <div className="widget birthday-widget">
      {headline && <h2 className="widget-title">{headline}</h2>}
      {your_birthday && (
        <span className={"space-x-1"}>
          <FontAwesomeIcon icon={faGift} />
          {I18n.t("js.widgets.birthday.gratulations")}
        </span>
      )}
      <ul className="memberships unstyled space-y-2">
        {memberships.map((member) => (
          <MemberRow key={member.id} member={member} showAge={show_age} />
        ))}
      </ul>
    </div>
  );
};

export default BirthdayWidget;
