import React from "react";
import PeopleWidget from "components/channels/widgets/PeopleWidget";
import ImagesWidget from "components/channels/widgets/ImagesWidget";
import TextWidget from "components/channels/widgets/TextWidget";
import ChannelRssWidget from "components/channels/widgets/RssWidget";

type PeopleWidgetProps = Parameters<typeof PeopleWidget>[0];
type TextWidgetProps = Parameters<typeof TextWidget>[0];
type ImagesWidgetProps = Parameters<typeof ImagesWidget>[0];
type ChannelRsssWidgetProps = Parameters<typeof ChannelRssWidget>[0];

type WidgetProps =
  | ({ type: "people" } & PeopleWidgetProps)
  | ({ type: "text" } & TextWidgetProps)
  | ({ type: "images" } & ImagesWidgetProps)
  | ({ type: "rss" } & ChannelRsssWidgetProps)
  | { type: string };

export default function WidgetByType({ type, ...props }: WidgetProps) {
  switch (type) {
    case "people": {
      const peopleProps = props as PeopleWidgetProps;
      return <PeopleWidget {...peopleProps} />;
    }
    case "text": {
      const textProps = props as TextWidgetProps;
      return <TextWidget {...textProps} />;
    }
    case "images": {
      const imagesProps = props as ImagesWidgetProps;
      return <ImagesWidget {...imagesProps} />;
    }
    case "rss": {
      const rssProps = props as ChannelRsssWidgetProps;
      return <ChannelRssWidget {...rssProps} />;
    }
    default:
      return window.Settings?.development ? (
        <div className={"alert alert-info prose"}>
          TODO Implement case for <code>{type}</code> in WidgetByTypes
        </div>
      ) : null;
  }
}
