import Label from "components/shared/form/Label";
import React from "react";
import { Control, Controller } from "react-hook-form";
import FileUpload from "components/shared/form/fields/FileUpload";

export default function FileUploadField({
  control,
  name,
  label,
  options,
  hint,
}: {
  name: string;
  label?: string;
  control?: Control<any>;
  options?: {
    isImage?: boolean;
    multiple?: boolean;
    selectExisting?: boolean;
    storageDirectory?: "images" | "assets" | "files";
  };
  hint?: string;
}) {
  return (
    <div className="control-group">
      {label && <Label label={label} />}
      <div className="controls">
        <Controller
          name={name}
          control={control}
          render={({ field }) => <FileUpload field={field} {...options} />}
        />
        {hint && <span className="text-muted text-sm">{hint}</span>}
      </div>
    </div>
  );
}
