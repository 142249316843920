import React from "react";

export default function TextWidget({ html_content }: { html_content: string }) {
  return (
    <div
      className="widget text-widget p-4 overflow-hidden"
      dangerouslySetInnerHTML={{ __html: html_content }}
    />
  );
}
