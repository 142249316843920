import React, { ReactNode, useState } from "react";
import PageTitle from "components/layout/PageTitle";
import { map } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarPlus } from "@fortawesome/pro-regular-svg-icons";
import { useCalendars } from "components/appointments/api";
import CalendarListView from "components/appointments/calendars/CalendarListView";
import { Calendar } from "../../../@types/appointments";
import CalendarSubscribeModal, {
  ModalOptions,
} from "components/appointments/calendars/CalendarSubscribeModal";

export default function SubscribeCalendarsPage() {
  const [showModal, setShowModal] = useState(false);
  const [modalOptions, setModalOptions] = useState<ModalOptions>();

  const { data: calendars, isLoading } = useCalendars();
  const calendarCollections = [
    "all_appointments",
    "participating_appointments",
    "personal_calendar",
    "shared_calendar",
    "network_calendar",
  ];

  function onShowModal(modalOptions: ModalOptions) {
    setModalOptions(modalOptions);
    setShowModal(true);
  }

  function actions(calendar: Calendar | undefined) {
    const buttons: ReactNode[] = [];

    buttons.push(
      <button
        key="subscribe"
        className="btn btn-light btn-sm"
        onClick={() =>
          onShowModal({
            title: I18n.t("calendars.calendar_manager.subscribe_calendar"),
            calendarId: calendar?.id,
          })
        }
      >
        <FontAwesomeIcon icon={faCalendarPlus} />
      </button>,
    );

    return buttons;
  }

  return (
    <>
      <PageTitle title={I18n.t("calendars.subscribe_calendars")} />
      {showModal && (
        <CalendarSubscribeModal
          calendars={calendars}
          modalOptions={modalOptions}
          setShowModal={setShowModal}
        />
      )}
      <div className="calendar-manager">
        {Preload.current_member?.authentication_token ? (
          <div className="all-region">
            <h3 className="font-semibold my-1">
              {I18n.t("js.calendars.subscriptions.calendar_collection_title")}
            </h3>
            <div className="prose my-1">
              {I18n.t("js.calendars.subscriptions.all_region_subscribe")}
            </div>
            <div className="divide-neutral divide-y unstyled border border-neutral rounded mb-4">
              {map(calendarCollections, (collectionType: string) => (
                <div
                  key={collectionType}
                  className="flex items-center justify-between px-3 py-2"
                >
                  {I18n.t(`js.calendars.subscriptions.${collectionType}`)}
                  <div className="btn-group">
                    <button
                      className="btn btn-light btn-sm"
                      onClick={() =>
                        onShowModal({
                          title: I18n.t(
                            `js.calendars.subscriptions.${collectionType}_subscribe`,
                          ),
                          collectionType,
                        })
                      }
                    >
                      <FontAwesomeIcon icon={faCalendarPlus} />
                    </button>
                  </div>
                </div>
              ))}
            </div>
            <div className="prose my-1">
              {I18n.t(
                "js.calendars.subscriptions.single_calendar_subscriptions",
              )}
            </div>
            <CalendarListView
              renderActions={actions}
              calendars={calendars}
              isLoading={isLoading}
            />
          </div>
        ) : (
          <a href="/users/edit">
            {I18n.t("js.calendars.subscriptions.no_access_token_found")}
          </a>
        )}
      </div>
    </>
  );
}
