import React from "react";
import PropTypes from "prop-types";
import { map } from "lodash";
import { compose, withState, withHandlers } from "recompose";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";

const GroupedItems = ({
  open,
  toggleOpen,
  items,
  title,
  allowToggle,
  renderItemComponent,
}) => (
  <div className="groupedRows px-3">
    {title ? (
      <div
        className={classNames("groupedRowsHeader my-2", { allowToggle })}
        onClick={allowToggle ? toggleOpen : null}
      >
        {allowToggle ? (
          <FontAwesomeIcon
            icon={faChevronRight}
            className={classNames(
              "fa-xs text-gray-500 transition-transform mr-2",
              {
                "rotate-90": open,
              },
            )}
          />
        ) : null}
        <span className="title font-semibold uppercase text-sm">{title}</span>
      </div>
    ) : null}
    {open ? (
      <div className="groupedRowsItems flex flex-wrap gap-1">
        {map(items, (item, index) => renderItemComponent(item, index))}
      </div>
    ) : null}
  </div>
);
GroupedItems.propTypes = {
  title: PropTypes.string,
  allowToggle: PropTypes.bool,
  open: PropTypes.bool,
  toggleOpen: PropTypes.func,
  getItemComponent: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.object),
  additionalItemProps: PropTypes.object,
};

export default compose(
  withState("open", "setOpen", true),
  withHandlers({
    toggleOpen:
      ({ open, setOpen }) =>
      () =>
        setOpen(!open),
  }),
)(GroupedItems);
