import React, { useEffect, useRef } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShare as faRegularShare,
  faSpinner as faRegularSpinner,
  faCalendarDay as faRegularCalendarDay,
  faFilter as faRegularFilter,
  faCircle as faRegularCircle,
  faTableCells as faRegularTableCells,
  faList as faRegularList,
  faCogs as faRegularCogs,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faCaretLeft as faSolidCaretLeft,
  faCaretRight as faSolidCaretRight,
  faCircle as faSolidCircle,
} from "@fortawesome/pro-solid-svg-icons";
import DropDown from "components/shared/DropDown";
import { ViewType } from "components/appointments/useAppointmentView";
import moment from "moment";
import { includes, isEmpty, map } from "lodash";
import { Calendar } from "../../@types/appointments";
import Datepicker from "helpers/Datepicker";
import { useParams } from "helpers/tixxt-router";
import { useSelector } from "react-redux";
import { State } from "../../@types";

type AppointmentToolbarArgs = {
  isLoading: boolean;
  view: ViewType;
  setViewType: (viewType: ViewType) => void;
  date: string;
  setDate: (date: string) => void;
  calendars?: Calendar[];
  toggleExcludedCalendarIds: (calendarId: string) => void;
  excludedCalendarIds: string[];
  scrollToToday: () => void;
  canCreate?: boolean;
};

export default function AppointmentToolbar({
  isLoading,
  view,
  setViewType,
  date,
  setDate,
  calendars,
  toggleExcludedCalendarIds,
  excludedCalendarIds,
  canCreate = false,
  scrollToToday,
}: AppointmentToolbarArgs) {
  const datePickerRef = useRef<HTMLInputElement>(null);

  const { groupSlug } = useParams();
  const group = useSelector((state: State) =>
    groupSlug ? state.groups.bySlug?.[groupSlug] : null,
  );
  const calendarId = !isEmpty(group)
    ? calendars?.find(
        (cal) =>
          cal.type === "group" &&
          cal.parent_id === group?.id &&
          cal.can.create_appointments,
      )?.id
    : undefined;

  useEffect(() => {
    const datepicker = new Datepicker(datePickerRef.current, {
      pickLevel: 1,
    });

    datepicker.element.addEventListener("changeDate", (event) =>
      setDate(moment(event.detail.date).format()),
    );

    return () => datepicker.destroy();
  }, [date, datePickerRef]);

  return (
    <div className="btn-toolbar mb-10">
      <div className="btn-group order-1">
        <button
          className={classNames("btn btn-light btn-sm", {
            active: moment(date).isSame(moment(), "day"),
          })}
          onClick={() => {
            setDate(moment().format());
            scrollToToday();
          }}
          title={I18n.t("js.calendars.overview.today")}
        >
          <FontAwesomeIcon icon={faRegularShare} className="mr-1" />
          {I18n.t("js.calendars.overview.today")}
        </button>
      </div>
      <div className="order-3 w-full text-center sm:order-2 sm:w-auto">
        <button
          className="btn"
          onClick={() => setDate(moment(date).subtract(1, "month").format())}
          title={I18n.t("js.calendars.overview.last_month")}
        >
          <FontAwesomeIcon icon={faSolidCaretLeft} />
        </button>
        <button
          className="btn btn-light datepicker-button relative"
          title={I18n.t("js.calendars.overview.current_month")}
          onClick={() => datePickerRef.current?.focus()}
        >
          {isLoading ? (
            <FontAwesomeIcon icon={faRegularSpinner} className="fa-spin" />
          ) : (
            <FontAwesomeIcon icon={faRegularCalendarDay} className="mr-1" />
          )}
          {moment(date).format("MMMM YYYY")}
          <input
            ref={datePickerRef}
            className="sr-only datepicker-input left-0 top-[38px]"
            value={moment(date).format(
              I18n.t("js.plugins.Locale.Formats.valid_date"),
            )}
            readOnly
          />
        </button>
        <button
          className="btn"
          onClick={() => setDate(moment(date).add(1, "month").format())}
          title={I18n.t("js.calendars.overview.next_month")}
        >
          <FontAwesomeIcon icon={faSolidCaretRight} />
        </button>
      </div>
      <div className="order-2 sm:order-3">
        <DropDown.Toggle
          active={!isEmpty(excludedCalendarIds)}
          disabled={!calendars || calendars.length === 0}
          title={I18n.t("js.calendars.overview.filter_calendars")}
          icon={faRegularFilter}
          small
        />
        <DropDown className="calendar-filter dropdown-menu" alignment="start">
          <DropDown.Item
            icon={
              !isEmpty(excludedCalendarIds) ? faRegularCircle : faSolidCircle
            }
            iconClassName="text-muted"
            text={I18n.t(
              !isEmpty(excludedCalendarIds)
                ? "js.calendars.overview.filter.include_all"
                : "js.calendars.overview.filter.exclude_all",
            )}
            onSelect={(e) => {
              e.preventDefault();
              toggleExcludedCalendarIds("all");
            }}
          />
          {map(calendars, (calendar) => (
            <DropDown.Item
              key={calendar.id}
              icon={
                includes(excludedCalendarIds, calendar.id)
                  ? faRegularCircle
                  : faSolidCircle
              }
              iconStyle={{ color: `#${calendar.color}` }}
              text={calendar.name}
              onSelect={(e) => {
                e.preventDefault();
                toggleExcludedCalendarIds(calendar.id);
              }}
            />
          ))}
        </DropDown>
        <div className="btn btn-group">
          <button
            className={classNames("btn btn-light btn-sm", {
              active: view === "sheet",
            })}
            onClick={() => setViewType("sheet")}
            title={I18n.t("js.calendars.overview.view.calendar_sheet")}
          >
            <FontAwesomeIcon icon={faRegularTableCells} />
          </button>
          <button
            className={classNames("btn btn-light btn-sm", {
              active: view === "list",
            })}
            onClick={() => setViewType("list")}
            title={I18n.t("js.calendars.overview.view.list")}
          >
            <FontAwesomeIcon icon={faRegularList} />
          </button>
        </div>
        <div className="btn-group">
          {canCreate && (
            <a
              className="btn btn-primary"
              href={`/appointments/new${
                calendarId ? "?calendar_id=" + calendarId : ""
              }`}
            >
              {I18n.t("js.calendars.overview.headline.add_appointment")}
            </a>
          )}
          <DropDown.Toggle />
          <DropDown className="dropdown-menu context-menu">
            <DropDown.Item
              url="/calendars/manage"
              icon={faRegularCogs}
              text={I18n.t("js.calendars.overview.headline.manage_calendars")}
            />
            <DropDown.Item
              url="/calendars/subscribe"
              icon={faRegularCalendarDay}
              text={I18n.t("js.calendars.overview.headline.calendar_subscribe")}
            />
          </DropDown>
        </div>
      </div>
    </div>
  );
}
