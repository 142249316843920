import { memoize } from "lodash";
import { mostReadable } from "@ctrl/tinycolor";

const readableTextColor = memoize((bgColor: string): string | undefined => {
  const normalText = getComputedStyle(document.body).getPropertyValue(
    "--normal-text",
  );
  return mostReadable(bgColor, [normalText, "white"], {
    includeFallbackColors: true,
  })?.toHexString();
});

export default readableTextColor;
