import React, { createContext } from "react";
import classNames from "classnames";
import "./widgets.css";
import readableTextColor from "helpers/readableTextColor";
import Loading from "components/shared/Loading";
import {
  getWidgetColspan,
  getWidgetContentHeight,
  getWidgetContainerResponsiveHeight,
} from "components/channels/helpers";

type Props = {
  title: string;
  titleBackgroundOverride: string | null;
  className?: string;
  w: number; // number of colums this widget occupies (1-3)
  children?: React.ReactNode;
  loading?: boolean;
};

export const WidgetContext = createContext<{ w: number }>({ w: 1 });

export default function WidgetContainer({
  title,
  titleBackgroundOverride,
  className,
  w,
  children,
  loading,
}: Props) {
  return (
    <WidgetContext.Provider value={{ w }}>
      <div
        className={classNames(
          "widget__container",
          className,
          w && getWidgetColspan(w),
          w && getWidgetContainerResponsiveHeight(w),
        )}
      >
        <div
          className={classNames(
            "widget__title flex items-center px-4 font-semibold text-sm rounded-tl rounded-tr ",
          )}
          style={
            titleBackgroundOverride
              ? {
                  backgroundColor: titleBackgroundOverride,
                  color:
                    readableTextColor(titleBackgroundOverride) ||
                    "var(--normal-text)",
                }
              : undefined
          }
        >
          <span>{title}</span>
        </div>
        <div
          className={classNames(
            "widget__content bg-white rounded-bl rounded-br grid",
            getWidgetContentHeight(w),
          )}
        >
          {loading ? <Loading /> : null}
          {children}
        </div>
      </div>
    </WidgetContext.Provider>
  );
}
