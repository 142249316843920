import React from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGift } from "@fortawesome/pro-regular-svg-icons";
import { isObject } from "lodash";

export type Member = {
  id: string;
  name: string;
  path: string;
  images: { "40x40@2": string };
  is_external: boolean;
  profile: { [key: string]: { displayValue: string } | string | null };
  birthday?: string;
  birthday_is_today?: boolean;
  age?: number;
};

type MemberRowProps = {
  member: Member;
  showAge?: boolean;
};

const MemberRow: React.FC<MemberRowProps> = ({ member, showAge }) => {
  return (
    <li className="membership media items-center">
      <img
        className="pull-left media-object w-10 h-10"
        src={member.images["40x40@2"]}
        alt={member.name}
      />
      <div className="media-body flex flex-col">
        <span className="chat-status pull-right"></span>
        <a className="media-heading" href={member.path}>
          {member.name}
        </a>
        {member.is_external && (
          <span className="label">{I18n.t("js.external_member.badge")}</span>
        )}
        <div className={"flex flex-col"}>
          {Object.entries(member.profile).map(([key, value]) => (
            <span key={key} className={`profile-${key}`}>
              {isObject(value) ? value.displayValue : value}
            </span>
          ))}
          {member.birthday && (
            <span className="birthday">
              {member.birthday_is_today ? (
                <span className={"space-x-1"}>
                  <FontAwesomeIcon icon={faGift} />
                  <strong>
                    {I18n.t("js.widgets.shared.member.birthday_is_today")}
                  </strong>
                  {showAge && member.age && ` (${member.age})`}
                </span>
              ) : (
                <>
                  {moment(member.birthday).format("L")}
                  {showAge && member.age && ` (${member.age + 1})`}
                </>
              )}
            </span>
          )}
        </div>
      </div>
    </li>
  );
};

export default MemberRow;
