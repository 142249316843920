import React from "react";
import PropTypes from "prop-types";
import { map, isEmpty, values, sum } from "lodash";
import classnames from "classnames";

import { QUERY_MAPPING, QueryParams } from "./SearchQueryResult";
import { searchUrl } from "./urls";

import styles from "./globalSearch.module.css";
import invariant from "invariant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons";
import { useSearchResults } from "components/globalSearch/api";

function FilterButton({
  queryName,
  total,
  routeParams,
  hits,
  disabled,
}: {
  queryName: string;
  total: number;
  routeParams: QueryParams["routeParams"];
  hits: QueryParams["hits"];
  disabled?: boolean;
}) {
  const propsFn = QUERY_MAPPING[queryName];
  invariant(propsFn, `No propsFn defined to render results for ${queryName}.`);
  const { title, fullResultUrl } = propsFn({ routeParams, hits });

  return (
    <li className={classnames({ disabled: !total })}>
      <a
        className={classnames("relative btn btn-light", {
          disabled: disabled,
        })}
        href={fullResultUrl || `#${queryName}-results`}
        onClick={disabled ? (e) => e.preventDefault() : undefined}
      >
        {title}
        {total > 0 ? (
          <span className="!absolute badge rounded-xl bg-unread -right-1.5 !-top-1.5">
            {total}
          </span>
        ) : null}
      </a>
    </li>
  );
}

function GlobalSearchButton({ q, enabled }: { q: string; enabled?: boolean }) {
  const { data } = useSearchResults({ q }, { enabled });
  const total = sum(map(values(data), "total"));

  return (
    <a
      // remove "module", because we want to remove any "module" filters
      // remove "page", because a) we want to start on page 1
      // and b) because if it's emtpy it still gets added and we do not want nor need this
      // (and it can cause problems with UserBar menu item highlighting)
      href={searchUrl({ q })}
      className={"btn btn-light relative"}
    >
      <FontAwesomeIcon icon={faArrowLeft} className={"text-primary mr-2"} />
      {I18n.t("js.global_search.filter.search_globally")}
      {total > 0 ? (
        <span className="!absolute badge rounded-xl bg-unread -right-1.5 !-top-1.5">
          {total}
        </span>
      ) : null}
    </a>
  );
}

function FilterModule({
  searchResults,
  routeParams,
  loading,
}: {
  searchResults: { [key: string]: { total: number; hits: object[] } };
  routeParams: QueryParams["routeParams"];
  loading?: boolean;
}) {
  if (isEmpty(routeParams.module) && isEmpty(searchResults)) return null;

  return (
    <ul
      className={classnames(
        "btn-toolbar justify-start",
        styles.globalSearchFilterBar,
      )}
    >
      {routeParams.module || routeParams.groupSlug ? (
        <li className={"pull-right"}>
          <GlobalSearchButton q={routeParams.q} enabled={!loading} />
        </li>
      ) : null}
      {map(searchResults, ({ total, hits }, queryName) => (
        <FilterButton
          key={queryName}
          queryName={queryName}
          total={total}
          routeParams={routeParams}
          hits={hits}
          disabled={!!routeParams.module}
        />
      ))}
    </ul>
  );
}

FilterModule.propTypes = {
  searchResults: PropTypes.object,
  routeParams: PropTypes.shape({
    q: PropTypes.string,
    module: PropTypes.string,
    groupSlug: PropTypes.string,
  }),
};

export default FilterModule;
