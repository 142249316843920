import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons";

type StatusButtonArgs = {
  changeStatus: () => void;
  icon: IconProp;
  isActive: boolean;
  className: string;
  isLoading: boolean;
  disabled?: boolean;
};

export default function StatusButton({
  changeStatus,
  icon,
  isActive,
  disabled,
  className,
  isLoading,
}: StatusButtonArgs) {
  return (
    <button
      onClick={onChangeStatus}
      className={`btn btn-light btn-sm ${isActive ? "active" : ""}`}
      disabled={isLoading || disabled}
    >
      <FontAwesomeIcon
        icon={isLoading ? faSpinner : icon}
        className={isLoading ? "interaction-icon-spinner" : className}
        spin={isLoading}
      />
    </button>
  );

  function onChangeStatus(e) {
    e.preventDefault();
    if (isActive) return;
    changeStatus();
  }
}
