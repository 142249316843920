import React from "react";
import { ceil, toString } from "lodash";
import Pagination from "components/shared/Pagination";
import { setSearchParam } from "helpers/tixxt-router";
import PageLoading from "components/shared/PageLoading";

type DirectoryPaginationArgs = {
  currentPage: number;
  isLoading: boolean;
  pagination?: {
    total: number;
    page: number;
    per_page: number;
  };
  children: React.ReactNode;
};

export default function DirectoryPagination({
  pagination,
  currentPage,
  isLoading,
  children,
}: DirectoryPaginationArgs) {
  const PaginationComponent = () => (
    <div className="flex justify-end">
      <Pagination
        page={currentPage}
        totalPages={pagination && ceil(pagination.total / pagination.per_page)}
        onPaginate={(page: number) => setSearchParam("page", toString(page))}
        loading={isLoading}
      />
    </div>
  );

  if (pagination && pagination.total > 5000) {
    return <p>{I18n.t("js.directory.too_large_collection")}</p>;
  }

  return (
    <>
      <PaginationComponent />
      {isLoading ? (
        <PageLoading />
      ) : (
        <div className="flex flex-col divide-y divide-neutral media-list">
          {children}
        </div>
      )}
      <PaginationComponent />
    </>
  );
}
