import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { State } from "../../@types";
import ToggleGroupFavoriteButton from "components/shared/favoriteButtons/ToggleGroupFavoriteButton";

import classNames from "classnames";
import ToggleGroupSubscribe from "components/shared/ToggleGroupSubscribeButton";
import LeaveGroupOption from "components/shared/GroupLeaveOption";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/pro-solid-svg-icons";

interface PageTitleTypes {
  title: string;
  subtitle?: string;
  renderHeadlineTools?: (() => React.ReactNode | null) | null;
  children?: React.ReactNode;
  setDocumentTitle?: boolean;
}

/*
 * Main "dumb" PageTitle component
 * Styles are duplicated in app/views/layouts/_content.html.haml
 */
export default function PageTitle({
  title,
  subtitle,
  renderHeadlineTools,
  children,
  setDocumentTitle = true,
}: PageTitleTypes) {
  if (renderHeadlineTools)
    console.warn("Prop renderHeadlineTools on PageTitle is deprecated");

  useEffect(() => {
    if (setDocumentTitle && !window.isApp) {
      document.title = `${title} | ${Preload.current_network.name}`;
    }
  }, [title]);

  return (
    <div className={"tixxt__pagetitle"}>
      <h1>{title}</h1>
      {subtitle ? <small>{subtitle}</small> : null}
      {children}
    </div>
  );
}

/*
 * Shows either the group name or the given title
 * Allows user to favorite the current group
 */
export function ContextPageTitle({
  groupSlug,
  title: titleProp,
}: {
  groupSlug?: string;
  title?: string | null;
}) {
  const group = useSelector((state: State) =>
    groupSlug ? state.groups.bySlug?.[groupSlug] : null,
  );
  const title = group?.name || titleProp;
  if (!title) return null;

  return (
    <PageTitle title={title}>
      {groupSlug && group && !Preload?.page_title ? (
        <>
          <div>
            <span className="dropdown">
              <button
                className="btn btn-sm btn-light border-0 dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                <FontAwesomeIcon icon={faEllipsisH} className={"text-muted"} />
              </button>
              <ul className="dropdown-menu context-menu left">
                {Preload?.current_member?.authentication_token ? (
                  <li>
                    <a
                      href={`/groups/${groupSlug}/activitystreams/${groupSlug}/activities.atom?auth_token=${Preload.current_member.authentication_token}`}
                      data-turbo={"false"}
                    >
                      {I18n.t("composer.editor.layout.abo")}
                    </a>
                  </li>
                ) : null}
                <ToggleGroupSubscribe groupSlug={groupSlug} />
                {group.can?.leave ? (
                  <>
                    <li className={"divider"} />
                    <LeaveGroupOption groupSlug={groupSlug} />
                  </>
                ) : null}
              </ul>
            </span>
            <ToggleGroupFavoriteButton groupSlug={groupSlug} />
          </div>
        </>
      ) : null}
    </PageTitle>
  );
}

export function Subtitle({
  className,
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) {
  return (
    <h2 className={classNames("uppercase font-semibold text-sm", className)}>
      {children}
    </h2>
  );
}
