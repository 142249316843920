import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCirclePlus,
  faList,
  faLocationDot,
} from "@fortawesome/pro-regular-svg-icons";
import React from "react";
import DirectoryToolbar from "components/directory/DirectoryToolbar";
import { Filter } from "components/directory/types";

type ToolBarArgs = {
  isLoading: boolean;
  filter: Filter;
};

export default function ToolBar({ isLoading, filter }: ToolBarArgs) {
  return (
    <DirectoryToolbar
      isLoading={isLoading}
      filter={filter}
      searchField={I18n.t("js.directory.member_tab")}
      showFilterInitially
      actions={
        <div className="flex gap-2">
          {Preload.current_member?.can.invite_internal ||
            (Preload.current_member?.can.invite_external && (
              <a
                className="btn btn-primary sm:ml-2 flex gap-1 items-center"
                href="/administration/invites/new"
                title={I18n.t("js.directory.headline_tools.create_channel")}
              >
                <FontAwesomeIcon icon={faCirclePlus} />
                {I18n.t("js.directory.headline_tools.invite_members")}
              </a>
            ))}
          {Preload.current_network.config.maps.enabled && (
            <div className="btn-group map-btn-group z-0">
              <a
                className="btn btn-light"
                href=""
                title={I18n.t("js.maps.list")}
              >
                <FontAwesomeIcon icon={faList} />
              </a>
              <a
                className="btn btn-light"
                href="/map"
                title={I18n.t("js.maps.map")}
              >
                <FontAwesomeIcon icon={faLocationDot} />
              </a>
            </div>
          )}
        </div>
      }
    />
  );
}
